
import VueRouter from 'vue-router'
import Login from './components/Login'
import Home from './components/Home'
import Eleicoes from './components/eleicoes/Eleicoes'
import Usuarios from './components/usuarios/Usuarios'
import Votacao from './components/votacao/Votacao'
import RealizaVoto from './components/votacao/RealizaVoto'
import profile from './profile'
import NotFound from './components/template/NotFound'

const routerDomain = process.env.VUE_APP_BASE_ROUTE;

const logout = (next) =>{
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return next(routerDomain);
}

const beforeEleicao = ((to, from, next)=>{
    if(profile().isComissao) {
        return next();
    }
    return logout(next);
});

const beforeUsuario = ((to, from, next)=>{
    if(profile().isAdministrador) {
        return next();    
    }
    return logout(next);
});

const beforeVotacao = ((to, from, next) => {
    if (profile().isEleitor || profile().isComissao) {
        return next();
    }
    return logout(next);
});
const protectRouter = ((to, from, next) => {
    let token = localStorage.getItem('token'),
        user = localStorage.getItem("user");        
    if (token == null && user == null) {
        next(routerDomain)
    } else{
        next();
    }
});


const routes = [
    {
        path: routerDomain,
        component: Login,
    },
    {
        path: `${routerDomain}/home`,
        component: Home,
        name:'home',
        children: [
            {
                path: 'eleicoes',
                component: Eleicoes,
                name: 'eleicoes',
                beforeEnter: beforeEleicao
            },
            {
                path: 'usuarios',
                component: Usuarios,
                name: 'usuarios',
                beforeEnter: beforeUsuario  
            },
            {
                path: 'votacao',
                component: Votacao,
                name: 'votacao',
                beforeEnter: beforeVotacao
            },
            {
                path: 'realizaVoto/:eleicaoid',
                component: RealizaVoto,
                name: 'realizaVoto',
                beforeEnter: beforeVotacao
            },
            {
                path: '*',
                component: NotFound,
            }
        ],
        beforeEnter:protectRouter
    },
    {
        path: '*',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
});

router.afterEach((to, from) => {
    $(".navbar-collapse").collapse('hide');
})

export default router;
