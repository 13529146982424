<template>
  <div>
    <div class="modal fade" id="cadastrarUsuario" tabindex="-1">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title w-300 font-weight-bold">Cadastrar Usuário</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <label for="strnome">Nome<span class="required-label">*</span></label>
                    <input type="text" ref="ref_strnome" id="strnome" class="form-control" v-model="usuario.strnome" maxlength="200" />
                </div>
                
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <label for="stremail">Email<span class="required-label">*</span></label>
                    <input ref="ref_stremail" type="text" class="form-control" id="stremail" v-model="usuario.stremail" maxlength="50"/>
                </div>
                
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <label for="strusuario">Usuário<span class="required-label">*</span></label>
                    <input ref="ref_strusuario" type="text" class="form-control" id="strusuario" v-model="usuario.strusuario" maxlength="15"/>
                </div>

                <div class="col-sm-12 col-md-4 col-lg-4">
                    <label for="intperfil_id">Tipo de Usuário<span class="required-label">*</span></label>
                    <select ref="ref_intperfil_id" id="intperfil_id" class="browser-default custom-select" v-model="usuario.intidperfil">
                        <option value="">Selecione</option>
                        <option :value="perfil.administrador">Administrador</option>
                        <option :value="perfil.comissao">Comissão</option>
                        <option :value="perfil.eleitor">Eleitor</option>
                    </select>
                </div>


                <div class="col-sm-12 col-md-4 col-lg-4">
                    <label for="strcelular">Telefone<span class="required-label">*</span></label>
                    <masked-input placeholder-char=" " ref="ref_strcelular"  id="strcelular" class="form-control" v-model="usuario.strcelular" maxlength="12" mask="(11) 11111-1111" />
                </div>
                
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <label for="strsenha">Senha<span class="required-label">*</span></label>
                    <input type="password" class="form-control" ref="ref_strsenha" id="strsenha" v-model="usuario.strsenha" maxlength="15"/>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <label for="strcsenha">Confirmar Senha<span class="required-label">*</span></label>
                    <input type="password" class="form-control" id="strcsenha" v-model="usuario.strcsenha" maxlength="15"/>
                </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-primary" :disabled='clicked' @click="saveUsuario()"><i class="fa fa-save mr-1 pr-1 "></i> &nbsp; Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as helpers from './../../helpers';
import MaskedInput from 'vue-masked-input'

export default {
  components:{
      MaskedInput
  },
  data() {
    return {
        usuario: {
            intidperfil:'',
        },
        clicked:false
    };
  },
  computed:{
      perfil () {
         return {
             administrador:process.env.VUE_APP_PERMISSAO_ADMINISTRADOR,
             comissao:process.env.VUE_APP_PERMISSAO_COMISSAO,
             eleitor:process.env.VUE_APP_PERMISSAO_ELEITOR
         }
      }
  },
  methods: {
    async saveUsuario() {
        if (helpers.isEmpty(this.usuario.strnome)) {
            toastr.error("Nome do usuário é obrigatório");
            this.$refs.ref_strnome.focus();
            return false;
        }
        if (helpers.isEmpty(this.usuario.stremail) || !helpers.isEmail(this.usuario.stremail)) {
            toastr.error("Insira um email válido");
            this.$refs.ref_stremail.focus();
            return false;
        }
        if (helpers.isEmpty(this.usuario.strusuario)) {
          toastr.error("O login do usuário é obrigatório");
          this.$refs.ref_strusuario.focus();
          return false;
        }
        if(this.usuario.strusuario.length < 5){
            toastr.error("O login do usuário deve conter no mínimo 5 caracteres");
            this.$refs.ref_strusuario.focus();
            return false;
        }
        if(helpers.isEmpty(this.usuario.intidperfil) || this.usuario.intidperfil ==0) {
            toastr.error("O perfil é obrigatório");
            this.$refs.ref_intperfil_id.focus();
            return false;
        }
        if(helpers.isEmpty(this.usuario.strcelular)) {
            toastr.error("O telefone é obrigatório");
            $('#strcelular').focus();
            return false;
        }
        if (helpers.isEmpty(this.usuario.strsenha)) {
            toastr.error("A senha é obrigatória");
            this.$refs.ref_strsenha.focus();
            return false;
        }
        if (this.usuario.strsenha != this.usuario.strcsenha) {
            toastr.error("As senhas não coincidem");
            this.$refs.ref_strsenha.focus();
            return false;
        }
      this.clicked = true;  
      await axios.post("/usuarios/cadastrar", this.usuario)
      .then(res => {
          toastr.success("Usuário cadastrado com sucesso");
          this.$emit('refreshComponent');
          $(".modal").modal("hide");
        },(e)=>{
          toastr.error(e.response.data.mensagem);
        });
        this.clicked = false;
    }
  }
};
</script>

<style>
</style>
  
