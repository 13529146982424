
import isMobile from 'ismobilejs'
import Swal from 'sweetalert2'

const tooltip = require('tooltip')

const tooltip_config = {
    showDelay: 0,
    style: {
        padding: 8,
        fontSize: '12pt',
        borderRadius: '3px',
        color: '#fff',
        backgroundColor: '#505050'
    }
}
if (!isMobile(navigator.userAgent).any) {
    tooltip(tooltip_config)
}

window.swal = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
    },
    buttonsStyling: false
});