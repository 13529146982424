import Axios from 'axios'

const axios = Axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers = {
        'Authorization': 'Bearer ' + token
    }
    return config;
});

axios.interceptors.response.use(function (config) {
    const token = config.data.refreshToken;
    if (token !== undefined) {
        localStorage.setItem('token', token);
    }
    return config;
}, function (error) {
    let login = localStorage.getItem('login');
    if (401 === error.response.status && login != "true") {
        swal.fire({
            title: 'Sessão Expirada',
            text: "Faça login novamente para continuar",
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowEscapeKey: false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                localStorage.setItem('token', '')
                location.href = process.env.VUE_APP_BASE_ROUTE;
            }
        });
    } else {
        return Promise.reject(error);
    }
});

export default axios;