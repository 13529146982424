import Vuex from 'vuex'
import state from './state'
import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const store = function () {
    return new Vuex.Store({
        state,
        getters,
        mutations,
        actions
    })
}
export default store;