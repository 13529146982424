<template>
  <div>
    <div class="container">
      <div
        class="modal fade right"
        id="modalVotar"
        data-keyboard="false"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalPreviewLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg "  role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalPreviewLabel">
                <i class="fas fa-vote-yea mr-1 pr-1"></i>
                {{eleicao.strdescricao}} - Restante:
                <span
                  class="font-weight-bold"
                  :class="{'red-text':votosRestantes==0}"
                >{{votosRestantes}} voto(s)</span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="note note-primary" style="font-size:11pt">
                    <div class="row">
                      <div class="col-sm-12 col-md-3">
                        <span class>
                          Total Candidato(s):
                          <strong>{{candidatosDB.length}}</strong>
                        </span>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <span class>
                          Tipo de Votação:
                          <strong>{{eleicao.inttipovotacao | tipoVotacao}}</strong>
                        </span>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <span class>
                          Permite votos em branco:
                          <strong>{{eleicao.boolvotobranco | YNFilter}}</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row card-votacao pl-3" id="cards-candidatos">
                  <div
                    class="col-md-4 col-lg-3 col-sm-12 text-center d-flex justify-content-center"
                    v-for="(c, index) in candidatosDB"
                    :key="index"
                    style="magin-bottom:2% !important"
                  >
                    <div
                      class="card testimonial-card"
                      :id="index"
                      @click="selecionarCandidato(index, c)"
                      style="cursor:pointer; width:180px; height:200px; margin-bottom:5%; margin-top:5%"
                    >
                      <div
                        class="card-up lighten-2 text-center align-center"
                        v-bind:style="{backgroundImage: 'url('+baseUrl +'/'+ c.tblfoto.strnomearquivo + ')'}"
                        style="background-size:cover; background-repeat:no-repeat; background-position:center; background-color:#fff;"
                      >
                        <div
                            v-if="c.strnumero"
                            style="width:30px;height:30px; position:absolute; right:5px;top:5px; text-align:center; line-height:30px; overflow:hidden"
                            class="rounded-circle blue white-text">
                            {{c.strnumero}}
                        </div>
                        <!-- <img :src="'http://11.98.1.247:3001/'+c.tblfoto.strnomearquivo" class="img-fluid" style="width:100%;height:100%"> -->
                      </div>

                      <div class="card-body" style="padding:2px !important;">
                        <span
                          class="card-title font-weight-bold"
                          style="margin-top:10%!important; text-transform:uppercase;"
                        >{{c.strnome}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <button type="button" class="btn btn-primary" @click="confirmarVotacao">
                  <i class="fa fa-check mr-1 pr-1"></i>
                  Confirmar voto
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from "./../../mixins";
export default {
  props: ["eleicao"],
  mixins: [Mixin],
  data() {
    return {
      selecionaveis: 0,
      dadosEleicao: {},
      candidatosDB: [],
      candidatosSelecionados: 0,
      iconVotoBranco: ""
    };
  },
  methods: {
    confirmarVotacao() {
      let candidatoSelected = [];
      candidatoSelected = $.grep(this.candidatosDB, candidato => {
        return candidato.selected == true;
      });

      for (let i = candidatoSelected.length; i < this.selecionaveis; i++) {
        candidatoSelected[i] = {
          branco: true
        };
      }
      if (!this.eleicao.boolvotobranco) {
        if (this.votosRestantes > 0) {
          toastr.error(
            `Todos os votos são obrigatórios. Restante(s):(${this.votosRestantes})`
          );
          return false;
        }
      }

      let candidatoNome,
        img,
        html = `
        <small class="alert alert-info"> A eleição será encerrada em: <strong>${this.dtaFinal}</strong></small>
        <br/>
        <ul class="list-group list-group-flush ul-small " style="max-height:300px; overflow:auto; margin-top:3%">
        <ul class="list-group">`;
      $.grep(candidatoSelected, candidato => {
        if (candidato.branco) {
          candidatoNome = "BRANCO";
          img = this.iconVotoBranco;
        } else {
          candidatoNome = candidato.strnome;
          img = this.baseUrl + "" + candidato.tblfoto.strnomearquivo;
        }
        html += `<li class="list-group-item d-flex align-items-center justify-align-center" style="font-size:10pt; text-align:center; padding-left:20%">
                        <div class="d-flex align-items-center">
                            <img class="img-fluid rounded-circle float-left border mr-3" style="width:40px" src="${img}"/>
                            <span class="font-weight-bold float-left" style="text-transform:uppercase">${candidatoNome}</span>
                        </div>
                    </li>`;
      });
      html += `</ul>`;

      swal
        .fire({
          title: "Confirmar Votação",
          html: html,
          width:700,
          allowEscapeKey:true,
          type: "question",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: false
        })
        .then(result => {
          if (result.value) {
            swal
              .fire(
                "Voto Computado!",
                "Você receberá um email com um código de confirmação de votação, utilize-o para conferir seus candidatos",
                "success"
              )
              .then(res => {
                $(".modal").modal("hide");
              });
          }
        });
    },
    selecionarCandidato(element, candidato) {
      let candidatoElement = $(`#${element}`),
        isSelected = candidatoElement.hasClass("selected");
      if (isSelected) {
        candidatoElement.removeClass("selected");
        this.candidatosSelecionados--;
        toastr.error(`Candidato ${candidato.strnome} desmarcado`);
        candidato.selected = false;
      } else {
        if (this.selecionaveis == this.candidatosSelecionados) {
          return false;
        }
        candidatoElement.addClass("selected");
        toastr.success(`Candidato ${candidato.strnome} marcado`);
        this.candidatosSelecionados++;
        candidato.selected = true;
      }
    }
  },
  computed: {
    votosRestantes() {
      return this.selecionaveis - this.candidatosSelecionados;
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
    dtaFinal() {
      return this.$options.filters.dateFull_br(this.eleicao.dtafinal);
    }
  },
  mounted() {
    let url = `/candidatos/eleicao/${this.eleicao.id}`;
    axios.get(url).then(res => {
      this.candidatosDB = res.data.candidatos;
    });
    this.iconVotoBranco = require("./../../../public/img/empty.png");
    this.selecionaveis = this.eleicao.intqtdcandidatosselecionaveis;
    this.dadosEleicao = this.eleicao;
  }
};
</script>

<style scoped>
.selected {
  border: 5px solid #0d47a1;
}
.candidato {
  background-color: darkcyan;
  width: 120px;
  height: 150px;
  margin-top: 3%;
}
.card-votacao {
  max-height: 400px !important;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}
.modal-dialog {
  width: 80% !important;
  height: 95% !important;
  margin: 2% auto auto auto;
  padding: 0 !important;
  max-width: none !important;
}

.modal-content {
  height: auto !important;
  min-height: 95% !important;
  border-radius: 0 !important;
}

.modal-header {
  border-bottom: 1px solid #9ea2a2 !important;
}

.modal-footer {
  border-top: 1px solid #9ea2a2 !important;
}
</style>