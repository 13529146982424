<template>
  <div>
    <div
      class="modal fade right"
      id="exampleModalPreview"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalPreviewLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalPreviewLabel">Cadastrar Eleição</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <label for="strdescricao_el">Título da eleição 
                   <span class="required-label">*</span>
                </label>
                <input
                  ref="strdescricao" 
                  type="text"
                  id="strdescricao_el"
                  autocomplete="off"
                  maxlength="255"
                  v-model="eleicao.strdescricao"
                  class="form-control"
                />
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="dtinicial">Data Inicial <span class="required-label">*</span></label>
                <datetime :phrases="{ok: 'Ok', cancel: 'Cancelar'}" input-id="dtinicial" type="datetime" zone="UTC-3" value-zone="UTC-3" format="dd/MM/yyyy HH:mm:ss" input-class="form-control" v-model="eleicao.dtainicial"></datetime>
              </div>
               
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="dtfinal">Data Final <span class="required-label">*</span></label>
                <datetime :phrases="{ok: 'Ok', cancel: 'Cancelar'}"  input-id="dtfinal" type="datetime" zone="UTC-3" value-zone="UTC-3" format="dd/MM/yyyy HH:mm:ss" input-class="form-control" v-model="eleicao.dtafinal"></datetime>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="inttpvotacao">Tipo votação <span class="required-label">*</span></label>
                <select
                  ref="inttpvotacao"
                  id="inttpvotacao"
                  class="browser-default custom-select"
                  @change="candidatosSelecionaveis"
                  v-model="eleicao.inttipovotacao"
                >
                  <option value disabled>Selecione</option>
                  <option value="1">Único</option>
                  <option value="2">Múltiplo</option>
                </select>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="intqtdeleitores">Max. Eleitores <span class="required-label">*</span></label>
                <input
                  type="number"
                  min="0"
                  ref="intqtdeleitores"
                  id="intqtdeleitores"
                  class="form-control"
                  v-model="eleicao.intqtdeleitores"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="selecionaveis">Qtd. Candidatos Selecionáveis <span class="required-label">*</span></label>
                <input
                  :disabled="eleicao.inttipovotacao == 1"
                  type="number"
                  min="0"
                  ref="selecionaveis"
                  id="selecionaveis"
                  class="form-control"
                  v-model="eleicao.intqtdcandidatosselecionaveis"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="intqtdvencedores">Qtd. Candidatos Vencedores <span class="required-label">*</span></label>
                <input
                  :disabled="eleicao.inttipovotacao == 1"
                  type="number"
                  min="0"
                  ref="intqtdvencedores"
                  id="intqtdvencedores"
                  class="form-control"
                  v-model="eleicao.intqtdvencedores"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="bolvotobranco">Habilitar voto branco</label>
                <select
                  id="bolvotobranco"
                  class="browser-default custom-select"
                  v-model="eleicao.boolvotobranco"
                >
                  <option value="false">NÃO</option>
                  <option value="true">SIM</option>
                </select>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="tpeleicao">Tipo de eleicao</label>
                <select
                  id="tpeleicao"
                  class="browser-default custom-select"
                  v-model="eleicao.boolpublica"
                >
                  <option value disabled>selecione</option>
                  <option value="true">Pública</option>
                  <option value="false">Privada</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
             <div class="col-sm-12 col-md-12 col-lg-12 text-center">
              <button type="button" class="btn btn-primary" :disabled="btnSalvar" @click="saveEleicao()">
                <i class="fa fa-save mr-1 pr-1 "></i>
                Salvar</button>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "../../mixins"
import moment from 'moment'
import * as helpers from '../../helpers'
export default {
  mixins: [Mixin],
  data() {
    return {
        btnSalvar:false,
        eleicao: {
            intsituacao: 1,
            inttipovotacao: "",
            boolvotobranco: true,
            boolpublica: false,
            intqtdeleitores: 0,
            intqtdcandidatosselecionaveis: 0,
            intqtdvencedores: 0
        }
    }
  },
  methods: {
    candidatosSelecionaveis(){
        var tipovotacao = this.eleicao.inttipovotacao
        var candidato =  $('#candidatosselecionaveis');
        var vencedores =  $('#intqtdvencedores');

        if(tipovotacao == 1){
            candidato.attr('disabled', true);
            this.eleicao.intqtdcandidatosselecionaveis = 1;
            vencedores.attr('disabled', true);
            this.eleicao.intqtdvencedores = 1;
         } else if(tipovotacao == 2) {
            candidato.prop('disabled', false);
            vencedores.prop('disabled', false);
        }
    },
    saveEleicao() {
      var eleicao = this.eleicao;
      
      if (helpers.isEmpty(eleicao.strdescricao)) {
            toastr.error("O Título da eleição é obrigatório");
            this.$refs.strdescricao.focus();
            return false;
      }

      if (helpers.isEmpty(eleicao.dtafinal) || helpers.isEmpty(eleicao.dtainicial)) {
            toastr.error("Os campos de data inicial e final são obrigatórios");
            return false;
      }

      let inicial = this.$options.filters.date_usa(eleicao.dtainicial),
          final   = this.$options.filters.date_usa(eleicao.dtafinal),
          isAfter = moment(new Date(final)).isAfter(new Date(inicial));
      
      if(!isAfter) {
        toastr.error('Configuração de data inválida');
        return false;
      }

      if(eleicao.inttipovotacao == ''){
        toastr.error("Selecione o tipo de votação");
        this.$refs.inttpvotacao.focus();
        return false;
      }
      if (eleicao.inttipovotacao == 2 && eleicao.intqtdcandidatosselecionaveis < 2) {
        toastr.error("Quantidade inválida de candidatos selecionáveis. Mínimo: 2");
        this.$refs.selecionaveis.focus();
        return false;
      }
      if (eleicao.intqtdeleitores <= 0 || eleicao.intqtdeleitores == undefined) {
        toastr.error("Quantidade de eleitores inválidos");
        this.$refs.intqtdeleitores.focus();
        return false;
      }
      if (eleicao.intqtdcandidatosselecionaveis < 1) {
        toastr.error("Quantidade inválida de candidatos selecionáveis. Mínimo: 1");
        this.$refs.intqtdeleitores.focus();
        return false;
      }
      if (eleicao.intqtdvencedores < 1) {
        toastr.error("Quantidade inválida de candidatos vencedores. Mínimo: 1");
        this.$refs.intqtdeleitores.focus();
        return false;
      }
      
      axios.post("/eleicoes/cadastrar", eleicao).then(
        res => {
            this.btnSalvar = false;
          this.$emit("refreshComponent");
          toastr.success("Eleição cadastrada com sucesso");
          $(".modal").modal("hide");
        }, (err)=>{
            this.btnSalvar = false;
            toastr.error(err.response.data.mensagem)
        }
      );
    }
  }
};
</script>