<template>
  <div id="app">
        <router-view></router-view>  
  </div>
</template>

<script>


export default {
  name: 'app'
}
</script>

<style>
.content{
    width: 90%;
    margin: 0 auto;
    padding-top: 70px;
    padding-bottom: 20px;
    /* margin-top: 5%; */
}
.modal-header {
    background-color: #0D47A1 !important;
    color:#fff;
}
.close {
    color: #fff;
}
.close:hover {
    color: #fff;
}
.badge-custom {
    padding:5px !important;
    font-weight: 300;
    font-size:10pt;
    text-transform: uppercase;
    box-shadow: none;
    border-radius: 5px;
}
.btn-custom {
    box-shadow: none;
}
.module-header{
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    font-weight: 400;
    color: #505050


}
</style>
