const fetchAllUsers = ({commit})=>{
    axios.get("/usuarios/listar").then(res => {
        commit('SET_USERS', res.data.usuarios);
    });
}
const forcerUpdate = ({commit})=>{
    commit('INCREMENT')
}
export{
    fetchAllUsers,
    forcerUpdate
}