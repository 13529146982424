<template>
  <div class="container">
    <div class="row" >
      <div class="col-sm-12 col-md-12 col-lg-21" style="padding: 0 1% 0 1%; margin-top:1%">
        <router-link  :to="{ name: 'votacao'}"><i class="fas fa-chevron-left sm-icon" ></i>  VOLTAR PARA AS ELEIÇÕES</router-link>
      </div>
      
      <div class="col-md-12" style="padding: 0 1% 0 1%; margin-top:1%">
        <h5>
          <i class="fas fa-vote-yea mr-1 pr-1"></i>
          {{eleicao.strdescricao}} - Restante:
          <span
            class="font-weight-bold"
            :class="{'red-text':votosRestantes==0}"
          >{{votosRestantes}} voto(s)</span>
        </h5>
        
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="col-md-12" style="padding: 0 1% 0 1%">
        <p v-if="usuarioVotou" class="note note-warning"><strong>Atenção:</strong> Você já votou nesta eleição</p>
        <div class="note note-primary" style="font-size:11pt">
          <div class="row" >
            <div class="col-sm-12 col-md-3">
              <span class>
                Total Candidato(s):
                <strong>{{candidatosDB.length}}</strong>
              </span>
            </div>
            <div class="col-sm-12 col-md-4">
              <span class>
                Tipo de Votação:
                <strong>{{eleicao.inttipovotacao | tipoVotacao}}</strong>
              </span>
            </div>
            <div class="col-sm-12 col-md-4">
              <span class>
                Permite votos em branco:
                <strong>{{eleicao.boolvotobranco | YNFilter}}</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-4 col-lg-3 col-sm-12 text-center d-flex justify-content-center"
        v-for="(c, index) in candidatosDB"
        :key="index"
        style="magin-bottom:2% !important"
        
      >
        <div
          class="card testimonial-card"
          :id="index"
          @click="selecionarCandidato(index, c)"
          style="cursor:pointer; width:250px; height:300px; margin:5% 0 5% 0"
        >
          <div
            v-if="c.strnumero"
            style="width:40px;height:40px; position:absolute; right:20px;top:15px; text-align:center; line-height:40px; overflow:hidden"
            class="rounded-circle blue white-text"
          >{{c.strnumero}}</div>
          <div class="card-body" style="padding:0 !important">
            <img
              :src="baseUrl + c.tblfoto.strnomearquivo"
              class="img-fluid"
              style="width:100%;height:210px; margin-bottom:1% !important"
            /><br/>
            <div
              class="font-weight-bold pt-3"
              style=" text-transform:uppercase;"
            >{{c.strnome}}</div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="col-sm-12 col-md-12 col-lg-12 text-center" v-if="this.eleicao.intsituacao == 3">
      <button v-if="!usuarioVotou" type="button" class="btn btn-lg btn-primary" @click="confirmarVotacao" :disabled="votoComputado" :class="{'btn-success':votoComputado}">
        <i class="fa fa-check mr-1 pr-1"></i>
        <span v-if="votoComputado">
            Voto Computado
        </span>
        <span v-else>
            Confirmar voto(s)
        </span>
      </button>
    </div>
  </div>
</template>
<script>
import Mixin from "./../../mixins";
export default {
  mixins: [Mixin],
  data() {
    return {
      votoComputado:false,
      candidatosDB: [],
      candidatosSelecionados: 0,
      iconVotoBranco: "",
      eleicao: {},
      usuarioVotou:'',
    };
  },
  methods: {
    async checaVotou () {
        await axios.get(`/votos/info/${this.$route.params.eleicaoid}`).then((res)=>{
            this.usuarioVotou = res.data.voto.id;
        }, (err)=>{
            console.log(err.response)
        });
    },
    async getEleicao() {
        await axios.get(`/eleicoes/info/${this.$route.params.eleicaoid}`).then(res => {
            this.eleicao = res.data.eleicao[0];
        });
    },
    confirmarVotacao() {
      let candidatoSelected = [];
      candidatoSelected = $.grep(this.candidatosDB, candidato => {
        return candidato.selected == true;
      });

      for (let i = candidatoSelected.length; i < this.selecionaveis; i++) {
        candidatoSelected[i] = {
          branco: true
        };
      }
      if (!this.eleicao.boolvotobranco) {
        if (this.votosRestantes > 0) {
          toastr.error(
            `Todos os votos são obrigatórios. Restante(s):(${this.votosRestantes})`
          );
          return false;
        }
      }

      let candidatoNome,
        img,
        html = `
        <small class="alert alert-info"> A eleição será encerrada em: <strong>${this.dtaFinal}</strong></small>
        <br/>
        <ul class="list-group list-group-flush ul-small " style="max-height:300px; overflow:auto; margin-top:3%">
        <ul class="list-group">`;
      var candidatouuid = [];
      candidatoSelected.forEach(element => {
        //   aqui
        if(!element.branco){
            candidatouuid.push(element.strcandidatouuid)
        } else {
            candidatouuid.push('branco');
        }
      });
        candidatouuid = candidatouuid.join(',');
    //   if(candidatouuid.length == 0) {
    //     candidatouuid.push('branco');

    //   } else{
    //   }

      $.grep(candidatoSelected, candidato => {
        if (candidato.branco) {
          candidatoNome = "BRANCO";
          img = this.iconVotoBranco;
        } else {
          candidatoNome = candidato.strnome;
          img = this.baseUrl + "" + candidato.tblfoto.strnomearquivo;
        }
        html += `<li class="list-group-item d-flex align-items-center justify-align-center" style="font-size:10pt; text-align:center; padding-left:20%">
                        <div class="d-flex align-items-center">
                            <img class="img-fluid rounded-circle float-left border mr-3" style="width:50px; height:50px;" src="${img}"/>
                            <span class="font-weight-bold float-left" style="text-transform:uppercase">${candidatoNome}</span>
                        </div>
                    </li>`;
      });
      html += `</ul>`;

      swal
        .fire({
          title: "Confirmar Votação",
          html: html,
          width: 700,
          allowEscapeKey: true,
          type: "question",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: false
        })
        .then(result => {
            if (result.value) {
                let data = {strcandidatouuid:candidatouuid, inteleicaoid:this.eleicao.id}
                axios.post('/votos/cadastrar', data).then((res)=>{
                    this.votoComputado = true;
                    swal
                    .fire(
                        "Voto Computado!",
                        "Você receberá um email com um código de confirmação de votação, utilize-o para conferir seus candidatos",
                        "success"
                    ).then(res => {
                        this.$router.push({ name: "votacao"});
                        $(".modal").modal("hide");
                    });
                }, (err)=>{
                toastr.error(err.response.data.mensagem);
            });
            }
        });
    },
    selecionarCandidato(element, candidato) {
      let candidatoElement = $(`#${element}`),
        isSelected = candidatoElement.hasClass("selected");
      if (isSelected) {
        candidatoElement.removeClass("selected");
        this.candidatosSelecionados--;
        toastr.error(`Candidato ${candidato.strnome} desmarcado`);
        candidato.selected = false;
      } else {
        if (this.selecionaveis == this.candidatosSelecionados) {
          return false;
        }
        candidatoElement.addClass("selected");
        toastr.success(`Candidato ${candidato.strnome} marcado`);
        this.candidatosSelecionados++;
        candidato.selected = true;
      }
    },
    getCandidatos() {
      let url = `/candidatos/eleicao/${this.$route.params.eleicaoid}`;
      axios.get(url).then(res => {
        this.candidatosDB = res.data.candidatos;
      });
    }
  },
  computed: {
    selecionaveis() {
      return this.eleicao.intqtdcandidatosselecionaveis;
    },
    votosRestantes() {
      return this.selecionaveis - this.candidatosSelecionados;
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
    dtaFinal() {
      return this.$options.filters.dateFull_br(this.eleicao.dtafinal);
    }
  },
  mounted() {
    this.getEleicao();
    this.getCandidatos();
    this.iconVotoBranco = require("./../../../public/img/empty.png");
    this.checaVotou();
  }
};
</script>

<style scoped>
.selected {
  border: 10px solid #0d47a1;
}
.candidato {
  background-color: darkcyan;
  width: 220px;
  height: 150px;
  margin-top: 3%;
}
.card-votacao {
  width: 100%;
  box-sizing: border-box;
}
</style>