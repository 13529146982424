const isEmpty = (value)=>{
    if(!Number.isInteger(value)){
        return (value === undefined || value == null || value.trim().length == 0);
    }
}

const isEmail = (email) => {
  let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export {
    isEmpty,
    isEmail
}