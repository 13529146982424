<template>
  <div>
    <div class="container">
      <div
        class="modal fade right"
        data-backdrop="static"
        id="modalabstencao"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalPreviewLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header no-print-div">
              <h5 class="modal-title" id="exampleModalPreviewLabel">Eleitores da {{this.eleicao.strdescricao}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" id="detalhesEleitores">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-2 logo text-center center">
                  <img :src="logo" class="rounded mx-auto d-block no-print" style="width:130px;margin-top:5px;" />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center mb-3 mt-3 no-print-div">
                  <select id="selTipoEleitores" v-model="tipoEleitores" @change="selectEleitores" class="browser-default custom-select no-print"  style="width:60%; margin:0 auto">
                    <option value="A">Abstenções</option>
                    <option value="B">Votantes</option>
                  </select> 
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12" id="detalhes_imprimir">
                  <div class="text-center mb-1" style="margin:0 auto; text-align:center; width:100%">
						<h4 class="text-center mb-1" style="margin:0 auto; text-align:center; width:100%">
							  <strong>{{this.eleicao.strdescricao}}</strong>
						</h4>
					  	<p class="mt-2 dates" style="margin:0 auto; text-align:center; width:100%">
							<span class="mr-1">
								Data Inicial:<strong>{{this.eleicao.dtainicial | dateFull_br}}</strong>
							</span>
							|
							<span class="ml-1">
								Data Final: <strong>{{this.eleicao.dtafinal | dateFull_br}}</strong>
							</span>
						</p>
					  </div>
					<div class="table-responsive">
                    <h5 v-if="tipoEleitores == 'A'" class="flow-text text-center txt-title">
                      <strong>Listagem nominal de abstenções</strong>
                    </h5>
                    <h5 v-else class="flow-text text-center txt-title" >
                      <strong> Listagem nominal de votantes</strong>
                    </h5>
					  
                    <table class="table table-striped table-bordered" style="width:90%; margin:0 auto">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Eleitores</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                        <tr v-if="isLoading" >
                          <td colspan="2" class="text-center">
                            <Loading />
                          </td>
                        </tr>
                        
                        <tr v-if="eleitores.length == 0 && isLoading == false" >
                          <td colspan="2" class="text-center">
                            <span >Nenhum Eleitor Encontrado</span>
                          </td>
                        </tr>

                        <tr v-for="(eleitor, index) in eleitores" :key="index">
                          <td style="width:20px">{{++index}}</td>
                          <td>{{eleitor.strusuario | eleitorNome(usuarioNome)}}</td>
                        </tr>
                      </tbody>
                    </table>
                  <br/>
                  </div>
                </div> 
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                
                <button type="button" class="btn btn-primary" :disabled="reportLoading" @click="printEleicao">
                  <span v-if="!reportLoading">
                    <i class="fa fa-print mr-1 pr-1"></i>
                    Imprimir
                  </span>
                  <Loading v-else title="gerando" :icon="false"/>
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from "./../../mixins";
import Loading from '../misc/Loading';
import FileSaver from 'file-saver';
import {v4 as uuid} from 'uuid';

export default {
  props:['eleicao'],
  mixins: [Mixin],
  components:{
      Loading
  },
  data() {
    return {
      eleitores:[],
      strusuariodb:[],
      isLoading:true,
      usuarios:[],
      usuarioNome:[],
      tipoEleitores: 'A',
      report: false,
      reportUrl:'',
      reportLoading:false
    };
  },
  methods: {
    async printEleicao() {
      this.reportLoading=true;
      let cssLink = `
      <style>
      *{
        font-family:sans-serif;
        font-size:10pt !important;
      }
      .table {
          font-size:10pt !important;
          margin:0 auto !important;
          border-collapse: collapse !important;
          border-spacing:0;
          width:100%;
      }
      .table-bordered th,
      .table-bordered td {
          border: 1px solid #999 !important;
      }
     /*
      table{
        page-break-inside:auto;
      }
      tr{
        page-break-inside:avoid;
        page-break-after:auto;
      }
      thead {
        display:table-header-group;
      }
      tfoot {
        display:table-footer-group;
      }
      */
      table tr td {
          padding-left:3px;
          padding-right:3px;
          border: 1px solid #999;
      }
      tbody tr:nth-child(odd) {
          background: #ddd;
      }
      .dates{
	  margin-top:1%;
      }
      .eleicao_desc{
	display: block;	
      }
      .txt-title{ 
          text-align:center; 
          margin:0 auto; 
          margin-top:2%;
          margin-bottom:2%;
      }
      select{ 
          display:none
      }
      .no-print{
        display:none !important
      }
      </style>`;
      const reportData = {
        "html":cssLink+$('#detalhesEleitores').html(),
        "pdf": {
          "displayHeaderFooter":"true",
          "headerTemplate" : "<img style=\"display: block; margin-left: 250px;\" src=\""+process.env.VUE_APP_IMG_B64_IMPRESSAO+"\" width=\"100\" height=\"70\">",
          "footerTemplate" : "<div class='footer' style='font-size: 6px !important; border-top: 1px solid #000; width: 100%; display:inline-block;'><div style='float: left; margin-left:10px; margin-top:5px;'>E-Voto | "+process.env.VUE_APP_RODAPE_IMPRESSAO+"</div><div style='float: right; margin-right:10px; margin-top:5px;'><div class='pageNumber' style='display:inline-block;'></div>/<div class='totalPages' style='display:inline-block;'></div></div></div>",
          "format" : "A4",
          "margin" : {
            "left" : "2cm",
            "top" : "4cm",
            "right" : "2cm",
            "bottom" : "2cm"
          }
        }
      }
      await axios.post(process.env.VUE_APP_URL_PDF, reportData,{
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }}).then(res=>{
        this.reportLoading=false;
        this.report=true;
        const blob = new Blob([res.data], { type: 'application/pdf' });
        FileSaver.saveAs(blob, uuid()+'.pdf');
      });
    },
    selectEleitores() {
      this.isLoading = true;
      this.eleitores = [];
      this.report = false;
      this.reportUrl = '';

      if(this.tipoEleitores == 'A') {
        this.getAbstencoes();
      } else if(this.tipoEleitores == 'B') {
        this.getVotantes();
      }
    },
    async getAllUsers() {
      let hasLDAP = process.env.VUE_APP_USER_LDAP;
      if (hasLDAP == "Y") {
        await axios.get("/usuarios/listar/ldap").then(res => {
          let usuarios = res.data.usuarios;
          usuarios.forEach(el=>{
            if(!this.strusuariodb.includes(el.strusuario)){
              this.usuarios = [...this.usuarios, el];
            }
          });
          
          usuarios.forEach(el=>{
            this.usuarioNome = [...this.usuarioNome, el];
          });

        });
      }
      await axios.get("/usuarios/listar").then(res => {
          let usuariosdb = res.data.usuarios, users = [];
          usuariosdb.forEach(el => {
            if (!this.strusuariodb.includes(el.strusuario)) {
              this.usuarios = [...this.usuarios, el];
            }
          });
          
          usuariosdb.forEach(el=>{
              this.usuarioNome = [...this.usuarioNome, el];
          });
          this.usuarios = _.orderBy(this.usuarios, "strnome");
      });

    },
    async getVotantes(){
        let inteleicaoid = this.eleicao.id;
        await axios.get(`/eleicoes/relatorio/votantes/${inteleicaoid}`).then(res => {
                this.eleitores = res.data.eleitores;
                this.isLoading = false;
        });
    },
    async getAbstencoes(){
        let inteleicaoid = this.eleicao.id;
        await axios.get(`/eleicoes/relatorio/abstencao/${inteleicaoid}`).then(res => {
                this.eleitores = res.data.eleitores;
                this.isLoading = false;
        });
    }
  },
  computed: {
    medal () {
      return require("../../assets/medal.png");
    },
    logo() {
      return process.env.VUE_APP_IMG_LOGO;
    },
    baseUrl(){
        return process.env.VUE_APP_BASE_URL;
    }
  },
  filters:{
    eleitorNome(strusuario, nomes) {
      var nome = '';
      nomes.forEach(el=>{
          if(strusuario == el.strusuario) {
            nome = el.strnome
          }
      });
      return nome;
    }
  },
  mounted() {
    this.getAllUsers();
    this.getAbstencoes();
  }
};
</script>
<style scoped>

.modal-dialog {
  width: 90% !important;
  height: 95% !important;
  margin: 2% auto auto auto;
  padding: 0 !important;
  max-width: none !important;
}

.modal-content {
  height: auto !important;
  min-height: 95% !important;
  border-radius: 0 !important;
}

#detalhes {
  padding: 0 !important;
}

.logo {
  width: 130px !important;
  margin:0 auto;
}

@media print {
  select {
    color:#fff !important;
    display:none !important;
  }
  option{
    color:#fff !important;
    display:none !important;
  }
  .no-print-div{
    color:#fff !important;
    display:none !important;
  }

  .no-print{
    display:none;
  }
  .list-candidatos{
    max-height: fit-content;
    overflow: hidden;
  }
}
</style>
