require('dotenv').config()
require('./plugins.config')
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import router from './routes'
import VueOffline from 'vue-offline'
import store from './store'
import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import axios from './services/axios'
import CKEditor from '@ckeditor/ckeditor5-vue';



require('./assets/css/app.css');


Vue.component('datetime', Datetime);
Vue.use(CKEditor);
Vue.use(VueOffline)
Vue.use(Datetime)

window.axios = axios;

Vue.use(VueRouter);
Vue.use(Vuex)
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
