import moment from 'moment';
// moment.locale('');
const mixins = {
    data() {
        return {

        }
    },
    methods:{
    },
    filters: {
        tipoVotacao(value) {
            if (value == 1) {
                return 'Única';
            } else if (value == 2) {
                return 'Múltipla';
            }
        },
        tipoEleicao(value) {
            if (!value) {
                return 'Privada';
            }
            return 'Pública';
        },
        YNFilter(value) {
            if (value) {
                return 'SIM';
            }
            return "NÃO";
        },
        situacao(value) {
            // Informar ID da situação (1 = Aberta, 2 = Expirada, 3 = Iniciada, 4 = Fechada)
            switch (value) {
                case 1:
                    return '<span class="badge badge-custom" style="background-color:orange" >Aberta</span>'
                    break;
                case 2:
                    return '<span class="badge badge-custom" style="background-color:gray">Expirada</span>';
                    break;
                case 3:
                    return '<span class="badge badge-custom badge-success">Iniciada</span>';
                    break;
                case 4:
                    return '<span class="badge badge-custom badge-danger">Finalizada</span>';
                    break;
                default: break;
            }
        },
        situacaoNoBadge(value) {
            // Informar ID da situação (1 = Aberta, 2 = Expirada, 3 = Iniciada, 4 = Fechada)
            switch (value) {
                case 1:
                    return 'Aberta'
                    break;
                case 2:
                    return 'Expirada';
                    break;
                case 3:
                    return 'Iniciada';
                    break;
                case 4:
                    return 'Finalizada';
                    break;
                default: break;
            }
        },
        date_usa(value) {
            if (!value) {
                return 'Data Inválida';
            }
            var ACCEPT_FORMAT = 'YYYY-MM-DD hh:mm';
            var start = moment.utc(value, ACCEPT_FORMAT)
            return moment(start).format('YYYY-MM-DD HH:mm');
        },
        date_br(value) {
            if (!value) {
                return 'Data Inválida';
            }
            var ACCEPT_FORMAT = 'YYYY-MM-DD hh:mm';
            var start = moment.utc(value, ACCEPT_FORMAT)
            return moment(start).format('DD/MM/YYYY');
        },
        dateFull_br(value) {
            if (!value) {
                return 'Data Inválida';
            }

            var start = moment.utc(value, 'YYYY-MM-DD hh:mm')
            /*if(process.env.VUE_APP_MOMENT_TZ) {
                return moment(start).subtract(3, 'hours').format('DD/MM/YYYY HH:mm');
            }*/
            return moment(start).format('DD/MM/YYYY HH:mm');

        }
    }
}

export default mixins