<template>
  <div class="container container-login">
    <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-4">
    </div>
    <div class="row area-login mt-3">
      <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <img :src="logo" class="logo-login img-fluid" />
        <h4 class="h4-responsive"></h4>
        <Loading title="Autenticando" v-if="isLoading"/>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-12">
        <label class="ml-1" for="strusuario">Usuário</label>
         
         <input
          type="text"
          id="strusuario"
          class="form-control mb-2 ml-1"
          v-model="login.strusuario"
          autocomplete="off"
          maxlength="50"
          v-on:keyup.enter="auth"
          :disabled="isLoading"
        /> 
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <label class="ml-1" for="strsenha">Senha</label>
        <input
          id="strsenha"
          type="password"
          class="form-control mb-3 ml-1"
          v-on:keyup.enter="auth"
          v-model="login.strsenha"
          maxlength="50"
          :disabled="isLoading"
        />
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 text-right">
        <button
        :disabled="isLoading"
          class="btn btn-block"
          style="background-color:#03669D; color:#fff; border-radius:3px;"
          @click="auth"
          type="button"
        >
          <i class="fas fa-sign-in-alt mr-1 pr-1"></i>
          Entrar
        </button>
           <!-- <a class="recSenhaOriginal outrasClass" v-if="ldap == 'Y'" href="javascript:;">Recuperar Senha</a>-->
      </div>
    </div>
  </div>
</template>

<script>
import MaskedInput from 'vue-masked-input'
import Loading from './misc/Loading'
import profile from './../profile'
export default {
    components:{
        Loading,
        MaskedInput
    },
  data() {
    return {
      login: {},
      isLoading: false,
      ldap: process.env.VUE_APP_USER_LDAP,
    };
  },
  methods: {
    async auth() {
      this.isLoading = true;
      if (this.login.strusuario === undefined || this.login.strsenha === undefined) {
        toastr.error("Todos os campos são obrigatórios");
        this.isLoading = false;
        return false;
      }
      this.login.strusuario = this.login.strusuario.trim();
     await axios.post("login", this.login)
        .then(res => {
          let user = {data:res.data.usuario, autenticacao:res.data.autenticacao}
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("login", false)
            
            if(profile().isComissao){
                this.$router.push({name:"eleicoes"});
            } else if (profile().isEleitor){
                this.$router.push({name:"votacao"});
            } else if (profile().isAdministrador) {
                this.$router.push({name:"usuarios"});
            }
            
        }, (err)=>{
            toastr.error(err.response.data.mensagem);
        })
        .catch(res => {
            console.log(`Error desconhecido:${res}`)
        });
        this.isLoading = false;
    }
  },
  computed: {
    logo() {
      return process.env.VUE_APP_IMG_LOGIN;
    }
  },
  mounted() {
    // localStorage.setItem("token", "login");
    localStorage.setItem("login", true);
  }
};
</script>

<style>
.container-login{
    margin-top:5%
}
.area-login {
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}
.logo-login {
  width: 300px;
}

@media (min-width: 768px) and (max-width: 979px) {
  .logo-login {
    width: 200px;
  }
  .area-login {
    width: 60%;
  }
}
@media (min-width: 979px) {
  .area-login {
    width: 30%;
  }
}
</style>
