import jwtDecode from 'jwt-decode';

const profile =  () => {
    var roles = {};
    try {
        let token = localStorage.getItem('token'),
            profile = jwtDecode(token);
            roles = {
                isAdministrador : (profile.intidperfil == process.env.VUE_APP_PERMISSAO_ADMINISTRADOR),
                isComissao : (profile.intidperfil == process.env.VUE_APP_PERMISSAO_COMISSAO),    
                isEleitor :(profile.intidperfil == process.env.VUE_APP_PERMISSAO_ELEITOR)
            };
    } catch (error) {
        return roles;
    }
    return roles;
}



export default profile;