<template>
  <div>
    <modal-detalhes :eleicao="eleicaoDetalhes" :key="update+2" v-if="showDetalhes"></modal-detalhes>
    <modal-votar :eleicao="eleicao" :key="update" v-if="openModal"></modal-votar>
    <modal-comprovante :eleicao="eleicao" :key="update+1" v-if="openModal"></modal-comprovante>
    <div class="container">
    <h3 class="h3-hesponsive module-header">Votação</h3>
      <div class="row">
        <div v-if="isLoading" class="col-sm-12 col-md-12 col-lg-12 text-center mb-5">
            <Loading />
        </div>
        <div v-if="!isLoading && eleicoes.length == 0" class="col-sm-12 col-md-12 col-lg-12 text-center mb-5 pt-5">
            <strong>Nenhum registro encontrado!</strong>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3" v-for="(e, index) in eleicoes" :key="index">
            <div class="card card-config" v-if="e.podevotar == 1">
                <div class="view overlay"><a><div class="mask rgba-white-slight"></div></a></div>
                <!-- Card content -->
                <div class="card-body">  
                <!-- Title -->
                <h4
                    class="card-title"
                    :class="{'font-adjust':e.strdescricao.length>40}"
                >{{e.strdescricao}}</h4>
                <hr />
                <!-- Text -->
                <p class="card-text">
                    <i class="fa fa-calendar mr-1 pr-1"></i>
                    Início: {{e.dtainicial | dateFull_br}}
                    <br />
                    <i class="fa fa-calendar mr-1 pr-1"></i>
                    Término: {{e.dtafinal | dateFull_br}}
                    <br />
                    <i class="fa fa-info-circle mr-1 pr-1"></i>
                    Eleição {{e.boolpublica | tipoEleicao}}
                </p>
                

                <a href="" data-toggle="modal" data-target="#modaldetalhes" 
                    v-if="e.intsituacao == 4 && e.boolresultado == 1" @click.prevent="openModalDetalhes(e)">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                        DETALHES DA ELEIÇÃO
                </a>

                <br/>
                <a href=""
                    :class="[e.intsituacao == 4 ? 'visible':'invisible']"
                    type="button"
                    data-tooltip-positions="top"
                    data-tooltip="Verificar comprovante de votação"
                    data-toggle="modal"
                    data-target="#modalComprovante"
                    @click="openModalComprovante(e)"
                    >
                    <i class="far fa-file-alt"></i> CONFERIR VOTO
                </a>
                </div>
                <div class="rounded-bottom mdb-color text-center">
                    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                        <button class="btn red white-text" style="cursor:not-allowed !important" v-if="e.podevotar == 0" >Sem Permissão</button>
                    </div> 
                    <div v-if="e.intsituacao != 3 && e.podevotar == 1" class="col-sm-12 col-md-12 col-lg-12">
                            <button class="btn btn-situacao" :class="{'btn-warning':e.intsituacao == 1, 'btn-danger':e.intsituacao == 4}" :disabled="true"><span v-html="$options.filters.situacaoNoBadge(e.intsituacao)"></span></button>
                    </div>
                    <div v-if="e.intsituacao == 3" class="col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="btn-group">
                                    <router-link v-if="e.votou == 0 && e.podevotar == 1" class="btn btn-primary"   :to="{ name: 'realizaVoto', params:{eleicaoid:e.id}}"><i class="fas fa-vote-yea mr-1 pr-1"></i>Votar</router-link>
                                    <button v-if="e.votou == 1" class="btn btn-success" :disabled="true">
                                        <i class="fas fa-check mr-1 pr-1"></i>Voto Computado
                                    </button>
                                    <button type="button" id="fake-button" data-toggle="modal" data-target="#modalVotar"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalDetalhes from "../eleicoes/ModalDetalhes";
import Mixin from "./../../mixins";
import ModalVotar from "./ModalVotar";
import ModalComprovante from "./ModalComprovanteVotacao";
import Loading from "./../misc/Loading"
export default {
  components: {
    ModalDetalhes,
    ModalVotar,
    ModalComprovante,
    Loading
  },
  mixins: [Mixin],
  data() {
    return {
      update: 0,
      eleicoes: [],
      eleicao: {},
      openModal: false,
      votou:[],
      showDetalhes:false,
      isLoading: true
    };
  },
  methods: {
    openModalDetalhes(e){
        this.update++;
        this.showDetalhes = true;
        this.eleicaoDetalhes = e;
    },
    async getAllEleicoes() {
        await axios.get("/eleicoes/listar").then(res => {
            let eleicoesdb = res.data.eleicoes; 
            for(const key in eleicoesdb) {
                if (eleicoesdb.hasOwnProperty(key)) {
                    if(eleicoesdb[key].podevotar == 1) {
                        this.eleicoes.push(eleicoesdb[key]);
                    }
                }
            }
        });
        this.isLoading = false
    },
    openModalComprovante(eleicao) {
      this.update++;
      this.openModal = true;
      Object.assign(this.eleicao, eleicao);
    }    
  },
  mounted() {
    this.getAllEleicoes(); 
  }
};
</script>
<style scoped>
.btn-situacao:disabled{
  color: #fff;
  opacity: 1;
  font-weight: bold ;
}
#fake-button{
  display: none;
}
.card-config {
   margin-bottom: 7%; 
}
.font-adjust {
  font-size: 12pt;
}

@media (min-width:1024) and (max-width: 1366){
    .card-config{
        margin-bottom: 30%;
    }
}
</style>
