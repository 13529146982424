<template>
  <div>
    <div
      class="modal fade right"
      id="editarEleicao"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalPreviewLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalPreviewLabel">Editar Eleição</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <label for="strdescricao">Título da eleição
                    <span class="required-label">*</span>
                </label>
                <input
                  type="text"
                  ref="strdescricao"
                  id="strdescricao"
                  autocomplete="off"
                  v-model="dados.strdescricao"
                  class="form-control"
                />
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="dtainicial">Data Inicial <span class="required-label">*</span></label>
                <datetime :phrases="{ok: 'Ok', cancel: 'Cancelar'}" v-model="dados.dtainicial" input-id="dtainicial" type="datetime"   format="dd/MM/yyyy HH:mm:ss" input-class="form-control" ></datetime>
               
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="dtafinal">Data Final <span class="required-label">*</span></label>
                <datetime v-model="dados.dtafinal" :phrases="{ok: 'Ok', cancel: 'Cancelar'}" input-id="dtafinal" type="datetime"   format="dd/MM/yyyy HH:mm:ss" input-class="form-control"></datetime>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="inttipovotacao">Tipo Votação <span class="required-label">*</span></label>
                <select
                  ref="inttipovotacao"
                  id="inttipovotacao"
                  class="browser-default custom-select"
                  @change="candidatosSelecionaveis"
                  v-model="dados.inttipovotacao"
                >
                  <option value disabled>Selecione</option>
                  <option value="1">Único</option>
                  <option value="2">Múltiplo</option>
                </select>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="intqteleitores">Max. Eleitores <span class="required-label">*</span></label>
                <input
                  type="number"
                  min="0"
                  id="intqteleitores"
                  ref="intqteleitores"
                  class="form-control"
                  v-model="dados.intqtdeleitores"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="candidatosselecionaveis">Qtd. Candidatos Selecionáveis <span class="required-label">*</span></label>
                <input
                  type="number"
                  :disabled="dados.inttipovotacao ==1"
                  min="0"
                  id="candidatosselecionaveis"
                  ref="candidatosselecionaveis"
                  class="form-control"
                  v-model="dados.intqtdcandidatosselecionaveis"
                />
              </div>              
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="selecionaveis">Qtd. Candidatos Vencedores <span class="required-label">*</span></label>
                <input
                  :disabled="dados.inttipovotacao == 1"
                  type="number"
                  min="0"
                  ref="intqtdvencedores"
                  id="intqtdvencedores"
                  class="form-control"
                  v-model="dados.intqtdvencedores"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="boolvotobranco">Habilitar Voto em Branco</label>
                <select
                  id="boolvotobranco"
                  class="browser-default custom-select"
                  v-model="dados.boolvotobranco"
                >
                  <option value="false">NÃO</option>
                  <option value="true">SIM</option>
                </select>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="tipoeleicao">Tipo de Eleição</label>
                <select
                  id="tipoeleicao"
                  class="browser-default custom-select"
                  v-model="dados.boolpublica"
                >
                  <option value disabled>selecione</option>
                  <option value="false">Pública</option>
                  <option value="true">Privada</option>
                </select>
              </div>

             
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
              <button type="button" @click="removeEleicao()" class="btn btn-danger">
                <i class="fas fa-trash mr-1 pr-1"></i> Apagar
              </button>
              <button type="button" class="btn btn-primary" @click="editarEleicao()">
                  <i class="fa fa-save mr-1 pr-1 "></i>
                  Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from "../../mixins";
import moment from "moment";
import * as helpers from "./../../helpers";
export default {
  mixins: [Mixin],
  props: ["eleicao"],
  data() {
    return {
      dados: {
          boolpublica:this.eleicao.boolpublica
      }
    };
  },
  methods: {
    dateConverter (date) {
        return moment(date).toISOString();
    },
    candidatosSelecionaveis() {
      var tipovotacao = this.dados.inttipovotacao;
      if (tipovotacao == 1) {
        document.getElementById("candidatosselecionaveis").disabled = true;
        document.getElementById("candidatosselecionaveis").value = 1;
        document.getElementById("intqtdvencedores").disabled = true;
        document.getElementById("intqtdvencedores").value = 1;
      } else if (tipovotacao == 2) {
        document.getElementById("candidatosselecionaveis").disabled = false;
        document.getElementById("intqtdvencedores").disabled = false;
      }
    },
    removeEleicao() { 
      swal.fire({
          text: "Deseja deletar a eleição " + this.eleicao.strdescricao + "?",
          title: "Deletar Eleição",
          type: "question",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: false
        })
        .then(result => {
          if (result.value) {
            axios.delete("/eleicoes/apagar/" + this.eleicao.id).then(res => {
              swal.fire(
                "Deletado!",
                "Eleição deletada com sucesso",
                "success"
              );
              this.$emit("refreshComponent");
               $(".modal").modal("hide");
            });
          }
        });
    },
    editarEleicao() {
    var eleicao = this.dados;
    
      if (helpers.isEmpty(eleicao.strdescricao)) {
            toastr.error("O Título da eleição é obrigatório");
            this.$refs.strdescricao.focus();
            return false;
      }
      if (helpers.isEmpty(eleicao.dtafinal) || helpers.isEmpty(eleicao.dtainicial)) {
            toastr.error("Os campos de data inicial e final são obrigatórios");
            return false;
      }

      let inicial = this.$options.filters.date_usa(eleicao.dtainicial),
          final   = this.$options.filters.date_usa(eleicao.dtafinal),
          isAfter = moment(new Date(final)).isAfter(new Date(inicial));
      
      if(!isAfter) {
        toastr.error('Configuração de data inválida');
        return false;
      }

      if (eleicao.inttipovotacao == "") {
        toastr.error("Selecione o tipo de votação");
        return false;
      }
      if (eleicao.intqtdeleitores <= 0 || eleicao.intqtdeleitores == "") {
        toastr.error("Quantidade de eleitores inválidos");
        this.$refs.intqteleitores.focus();
        intqteleitores
        return false;
      }
      if ( eleicao.inttipovotacao == 2 && eleicao.intqtdcandidatosselecionaveis < 2 ) {
        toastr.error("Quantidade inválida de candidatos selecionáveis. Mínimo: 2");
        this.$refs.candidatosselecionaveis.focus();
        return false;
      }
      if (eleicao.intqtdcandidatosselecionaveis < 1) {
        toastr.error(
          "Quantidade inválida de candidatos selecionáveis. Mínimo: 1"
        );
        return false;
      }
      if (eleicao.intqtdvencedores < 1) {
        toastr.error(
          "Quantidade inválida de candidatos Vencedores. Mínimo: 1"
        );
        return false;
      }
      axios.put(`/eleicoes/alterar/${eleicao.id}`, eleicao).then(
        res => {
          toastr.success("Alterado com sucesso");
          this.$emit("refreshComponent");
          setTimeout(res => {
            $(".modal").modal("hide");
          }, 1000);
        },
        err => toastr.error(err.response.data.mensagem)
      );
    }
  },
  mounted() {
    this.dados = this.eleicao;
    this.eleicao.dtainicial = this.dateConverter(this.eleicao.dtainicial);
    this.eleicao.dtafinal = this.dateConverter(this.eleicao.dtafinal);
  }
};
</script>