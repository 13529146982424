<template>
  <div>
    <div class="container">
      <div
        class="modal fade right"
        data-backdrop="static"
        id="modaljustificativa"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalPreviewLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header no-print-div">
              <h5 class="modal-title" id="exampleModalPreviewLabel">Eleitores da {{this.eleicao.strdescricao}} > Justificar Desempate</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" id="detalhesJustificativa">
              <label for="strjustificativa">Especifique o(s) candidato(s) vencedor(es) e a Justificativa de Desempate</label>
              <ckeditor :editor="editor" v-model="justificativa" @ready="onReady" :config="editorConfig"></ckeditor>
            </div>  
            <div class="modal-footer center">
              <div class="col-sm-12 col-md-12 col-lg-12 text-center">                
                <div>
                <BtnLoading
                  style="text-center"
                  icon="fa fa-save"
                  label="Salvar"
                  :isLoading="isLoading"
                  v-on:handle="salvarJustificativa"
                  :button-class="'btn btn-primary waves-effect waves-light'"
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from "./../../mixins";
import Loading from '../misc/Loading';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import BtnLoading from '../misc/BtnLoading'
import {isEmpty} from '../../helpers'

import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/pt-br';

export default {
  props:['eleicao'],
  mixins: [Mixin],
  components:{
      Loading,
      BtnLoading
  },
  data() {
    return {
      isLoading: false,
      editor: DecoupledEditor,
      justificativa:this.eleicao.strjustificativa,
      editorConfig:{
        language: 'pt-br'
      }
    };
  },
  methods: {
    onReady( editor ) {
        // Insert the toolbar before the editable area.
        editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
        );
    },
    async salvarJustificativa () {
      this.isLoading = true
      if(isEmpty(this.justificativa)) {
        toastr.error("A justificativa é obrigatória");
        this.isLoading = false;
        return false;
      }
      await axios.put(`/eleicoes/justificar/${this.eleicao.streleicaouuid}`, {
        strjustificativa: this.justificativa
      }).then(res => {
        this.$emit('fetch')
        toastr.success(res.data.mensagem)
        this.isLoading = false;
      }).catch(err=>{
        toastr.error("Erro Interno");
        this.isLoading = false;
      })
    } 
  },
  computed: {
    
  },
  filters:{
  
  },
  mounted() {
  
  }
};
</script>
<style>
.ck-content { 
  width: 100% !important; 
  height:400px !important; 
}
</style>

