<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center fa-blink" style="margin: 0 auto">
                <span v-if="!showIcon">
                    <img :src="imgLoading" alt="" width="30px">
                    <br/>
                </span>

                <span v-if="!title">Carregando</span>
                <span v-if="title">{{title}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['title'],
    computed:{
        imgLoading(){
            return require('../../../public/img/loading.svg')
        },
        showIcon(){
            return (this.icon == false);
        }
    }
}
</script>
<style scoped>
 @keyframes fa-blink {
     0% { opacity: 1; }
     50% { opacity: 0.5; }
     100% { opacity: 0; }
 }
.fa-blink {
   -webkit-animation: fa-blink 1.1s linear infinite;
   -moz-animation: fa-blink 1.1s linear infinite;
   -ms-animation: fa-blink 1.1s linear infinite;
   -o-animation: fa-blink 1.1s linear infinite;
   animation: fa-blink 1.1s linear infinite;
}
</style>