<template>
  <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <div class="notfound-css">
        <h1>404</h1>
        <p>PÁGINA NÃO ENCONTRADA</p>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .notfound-css{
        margin-top: 10%;
        /* font-weight: bold; */
        font-size: 20pt;
    }
</style>