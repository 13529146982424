<template>
  <div>
    <div class="container">
      <div
        class="modal fade right"
        data-backdrop="static"
        id="modaldetalhes"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalPreviewLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalPreviewLabel">Detalhes da Eleição</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" id="detalhes">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-2 logo text-center center">
                  <img :src="logo" class="rounded mx-auto d-block no-print" style="width:130px;margin-top:5px;" />
                </div>
           
                <div class="col-md-12 col-sm-12 col-lg-12">
                  <div class="table-responsive">
                    <div class="list-candidatos">
                     <table class="table">
                      <thead style="text-align:center">
                        <th colspan="4">
                          <h5>{{eleicao.strdescricao}}</h5>
                          <Loading v-if="isLoading"/>
                        </th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Situação:
                            <strong>
                              <span v-html="$options.filters.situacaoNoBadge(eleicao.intsituacao)"></span>
                            </strong>
                          </td>
                          <td>
                            Data Inicial:
                            <strong>{{eleicao.dtainicial | dateFull_br}}</strong>
                          </td>
                          <td>
                            Data Final:
                            <strong>{{eleicao.dtafinal | dateFull_br}}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Tipo Votação:
                            <strong>{{eleicao.inttipovotacao | tipoVotacao}}</strong>
                          </td>
                          <td>
                            Quantidade de Eleitores:
                            <strong>{{eleicao.intqtdeleitores}}</strong>
                          </td>
                          <td>
                            Quantidade de Candidatos Selecionáveis:
                            <strong>{{eleicao.intqtdcandidatosselecionaveis}}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Qtd. de Candidatos Vencedores:
                            <strong>
                              <span>
                                {{this.eleicao.intqtdvencedores}}
                              </span>
                            </strong>
                          </td>
                          <td>
                            Tipo de Eleição:
                            <strong>{{eleicao.boolpublica | tipoEleicao}}</strong>
                          </td>
                          <td>
                            Permite voto em branco:
                            <strong>{{eleicao.boolvotobranco | YNFilter}}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Votos em branco:
                            <strong>
                              <span v-if="eleicao.intsituacao == 4">
                                {{this.apuracao.total_brancos}}
                              </span>
                              <span v-else>0</span>
                            </strong> 
                          </td>
                          <td>
                            Abstenções:
                            <strong>
                              <span v-if="eleicao.intsituacao == 4">
                                {{this.apuracao.total_abstencao}}
                              </span>
                              <span v-else>0</span>
                            </strong>
                          </td>
                          <td>
                            Votos Válidos:
                            <strong>
                              <span v-if="eleicao.intsituacao == 4">
                                {{this.apuracao.total_votos}}
                              </span>
                              <span v-else>0</span>
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Votos Possíveis:
                            <strong>{{votos_possiveis}}</strong>
                          </td>
						  
						  <td colspan="2">
                            Cadastrado por:
                            <strong>{{this.strcadastrado}}</strong>
                          </td>
						  
                        </tr>
                      </tbody>
                    </table> 
                    <br>
		    <div class="col-sm-12 col-md-12 col-lg-12" v-if="eleicao.boolresultado == 1 && eleicao.strjustificativa">
                      <span class="negrito">Justificativa de Desempate:</span>
                      <p class="pl-2 alert alert-info">
                         <span v-html="eleicao.strjustificativa"></span>
                      </p>
                    </div>
		   <br/>           
                    <table class="table table-striped"  >
                        <thead>
                            <tr>
				<th>
                                   <strong>Candidatos</strong>
                                </th>
                                <th class="text-center">
                                    <strong>Votos</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="isLoading">
                                <td colspan="3" class="text-center">
                                    <Loading />
                                </td>
                            </tr>
                            <tr v-if="candidatos.length == 0">
                                <td colspan="2" style="text-align:center">
                                   <strong>Nenhum candidato para essa eleição</strong>
                                </td>
                            </tr>
                            <tr v-for="(c, index) in candidatos" :key="index" :class="{'green lighten-4':checkGanhador(c)}">
				                                
				<td style="text-align: left; vertical-align: middle !important;" class="td-align">
          <div style="float:left !important; width: min-content !important;">
            <div style="position:relative !important; width: 100%;">
                <img v-if="eleicao.intsituacao != 4" :src="baseUrl+c.tblfoto.strnomearquivo" style="width:100px; height:auto; margin-left:3% !important">
                <img v-else :src="baseUrl+c.strnomearquivo" style="width:100px; height:auto; margin-left:3% !important; z-index:1 !important;">
                <div class="badge badge-custom numero-impressao" v-if="c.strnumero" style="line-height: inherit !important; position: absolute; z-index:2 !important; right: 0 !important; bottom: 0 !important; color:#fff !important; font-weight:bold; background-color:blue !important; border-radius:50% !important; overflow: hidden !important; width:50% !important; max-width:30px !important; height:min-content !important; text-align:center !important; vertical-align:middle !important;display: table-cell !important;" >
                  <span class="padding-bottom:50%;line-height: inherit !important;">{{c.strnumero}}</span>
                </div>
            </div>
          </div>
          <div style="float:left !important; width: 80% !important; margin-left:3% !important; vertical-align: middle !important;">
            <span style="font-size:12pt;"  :class="{'ganhador':checkGanhador(c)}"><br>
                {{c.strnome}}
                <strong v-if="checkGanhador(c)">&nbsp;(<img :src="medal" style="width:20px; height:20px;"/>)</strong><br/>
            </span>
          </div>
                                </td>
                                <td  style="text-align: center;" class="text-center">
                                  <div style="font-size:12pt;" class="text-center">
                                    <span v-if="eleicao.intsituacao == 1 || eleicao.intsituacao == 2" ><br>
                                        0
                                    </span>
                                    <span v-if="eleicao.intsituacao == 3"><br>
                                        Aguardando finalização da eleição
                                    </span>
                                    <span v-if="eleicao.intsituacao == 4"><br>
                                      {{c.total_votos}}
                                      ({{c.porcentagem}})
                                    </span>
                                  </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <button
                  v-if="!report"
                  type="button"
                  class="btn btn-primary"
                  :disabled="reportLoading"
                  @click="printEleicao"
                >
                <span v-if="!reportLoading">
                  <i class="fa fa-print mr-1 pr-1"></i>
                  Imprimir
                </span>

                  <Loading v-else title="gerando" :icon="false"/>
                </button>

                <a :href="reportUrl" target="_blank"
                  v-else
                  type="button"
                  class="btn btn-primary"
                  
                >
                  <i class="fas fa-file-download mr-1 pr-1"></i>
                  Baixar
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Mixin from "./../../mixins";
import Loading from '../misc/Loading';
import FileSaver from 'file-saver';
import {v4 as uuid} from 'uuid';

export default {
  props:['eleicao'],
  mixins: [Mixin],
  components:{
      Loading
  },
  data() {
    return {
      dadosEleicao: {},
      strcadastrado: "",
      candidatos:[],
      candidatos_secundario:[],
      apuracao:[],
      ganhador:[],
      candidatoResults:[],
      isLoading:true,
      reportLoading:false,
      report: false,
      reportUrl:'',
      votos_possiveis: 0
    };
  },
  methods: {
    async printEleicao() {
      this.reportLoading=true;
      let cssLink = `
      <style>
      *{
        font-family:sans-serif;
        font-size:10pt !important;
      }
      .numero-impressao{
        min-height: 23px !important;
        padding-top:7px !important;
        vertical-align: middle !important;
        line-height: inherit !important;
      }
      .table {
          font-size:11pt !important;
          margin:0 auto !important;
          border-collapse: collapse !important;
          border-spacing:0;
          width:100%;
      }
      .td-align{
        display:flex;
        align-items:center;
        justify-align:space-beetween;
        padding:1%;
      }
      .table-bordered th,
      .table-bordered td {
          border: 1px solid #999 !important;
      }
      table{
        page-break-inside:auto;
      }
      tr{
        page-break-inside:avoid;
        page-break-after:auto;
      }
      #c_nome_candidato{
        margin-botton:80% !important;
      }
      thead {
        display:table-header-group;
      }
      tfoot {
        display:table-footer-group;
      }
      table tr td {
          padding-left:5px;
          padding-right:5px;
          border: 1px solid #999;
      }
      tbody tr:nth-child(odd) {
          background: #ddd;
      }
      .txt-title{ 
          text-align:center; 
          margin:0 auto; 
          margin-top:7%;
          margin-bottom:7%;
      }
      select{ 
          display:none
      }
      .no-print{
        display:none !important
      }
      </style>`;
      const reportData = {
        "html":cssLink+$('#detalhes').html(),
        "pdf": {
          "displayHeaderFooter":"true",
          "headerTemplate" : "<img style=\"display: block; margin-left: 250px;\" src=\""+process.env.VUE_APP_IMG_B64_IMPRESSAO+"\" width=\"100\" height=\"70\">",
          "footerTemplate" : "<div class='footer' style='font-size: 6px !important; border-top: 1px solid #000; width: 100%; display:inline-block;'><div style='float: left; margin-left:10px; margin-top:5px;'>E-Voto | "+process.env.VUE_APP_RODAPE_IMPRESSAO+"</div><div style='float: right; margin-right:10px; margin-top:5px;'><div class='pageNumber' style='display:inline-block;'></div>/<div class='totalPages' style='display:inline-block;'></div></div></div>",
          "format" : "A4",
          "margin" : {
            "left" : "2cm",
            "top" : "4cm",
            "right" : "2cm",
            "bottom" : "2cm"
          }
        }
      }
      axios.post(process.env.VUE_APP_URL_PDF, reportData,{
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }}).then(res=>{
        this.reportLoading=false;
        const blob = new Blob([res.data], { type: 'application/pdf' });
        FileSaver.saveAs(blob, uuid()+'.pdf');
      });
    },
    async getCandidatosByEleicao(){
        let inteleicaoid = this.eleicao.id;
        await axios.get(`candidatos/eleicao/${inteleicaoid}`).then(res => {
                this.isLoading = false;
                this.candidatos = res.data.candidatos;
        });
    },
    async getUserInfo() {
      let intcadastradoid = this.eleicao.intcadastradoid;
       await axios.get(`/usuarios/info/${intcadastradoid}`).then(
        res => {
          this.strcadastrado = (this.autenticacao == 'ldap')? res.data.usuarioLDAP.strnome : res.data.usuario.strnome;
        },
        err => {
            console.log(err)
        }
      ).catch((err) => console.log(err));
    },
    async getApuracao(){
        if(this.eleicao.intsituacao != 4) return false;
        await axios.get(`/eleicoes/apuracao/${this.eleicao.id}`).then((res)=>{
            this.apuracao = res.data;
            let ganhador = []
            let candidatos = this.apuracao.resultado.sort((a,b) =>  b.total_votos-a.total_votos);
            let conta = 0;
            this.candidatos = this.apuracao.resultado;
            this.isLoading = false;
                this.candidatos.forEach(el => {
                  //Adiciona a maior pontuação
                  if(conta == 0 && this.eleicao.intsituacao == 4) ganhador.push(el.total_votos);
                  //Se atingiu a maior pontuação adiciona a medalha
                  if(el.total_votos == ganhador[0]){
                    conta++;
                    this.ganhador.push(el.id);
                  }
                  this.candidatoResults.push(el);
                });
            });
    },
    checkGanhador (candidato) {
      return this.ganhador.includes(candidato.id);
    }
  },
  filters:{
    candidatosInfo (candidatoId, candidatoResults) {
      let result = 0;
      candidatoResults.forEach(candidato =>{
        if(candidato.id == candidatoId){
          result = candidato.total_votos;
        }
      });
      return result;
    },
    candidatosPorcentagem (candidatoId, candidatoResults) {
      let result = 0;
      candidatoResults.forEach(candidato =>{
        if(candidato.id == candidatoId){
          result = candidato.porcentagem;
        }
      });
      return result;
    }
  },  
  computed: {
    autenticacao(){
        const user = JSON.parse(localStorage.getItem('user'));
        return (!user)? '' : user.autenticacao;
    },
    medal () {
      return require("../../assets/medal.png");
    },
    logo() {
      return process.env.VUE_APP_IMG_LOGO;
    },
    baseUrl(){
        return process.env.VUE_APP_BASE_URL;
    }
  },
  mounted() {
    this.votos_possiveis = parseInt(this.eleicao.intqtdeleitores) * parseInt(this.eleicao.intqtdcandidatosselecionaveis);
    this.dadosEleicao = this.eleicao;
    this.getUserInfo();
    if(this.eleicao.intsituacao == 4) {
	    this.getApuracao();
    } else {
      console.log('executou o de candidatos') 
      this.getCandidatosByEleicao();   
    }
  }
};
</script>
<style scoped>
.negrito{
  font-weight: bold !important;
}
.ganhador{
  font-weight: bold
}
.modal-dialog {
  width: 90% !important;
  height: 95% !important;
  margin: 2% auto auto auto;
  padding: 0 !important;
  max-width: none !important;
}

.modal-content {
  height: auto !important;
  min-height: 95% !important;
  border-radius: 0 !important;
}

#detalhes {
  padding: 0 !important;
}

.logo {
  width: 130px !important;
  margin:0 auto;
}
.list-candidatos{
    max-height:fit-content; 
    overflow-y:auto;
}
@media print {
  .list-candidatos{
    max-height: fit-content;
    overflow: hidden;
  }
}
</style>
