<template>
  <div>
    <modal-detalhes :eleicao="eleicaoDetalhes" :key="updComponentes+4" v-if="opendetalhes"></modal-detalhes>
    <modal-abstencoes :eleicao="eleitoresAbstencao" :key="updComponentes+4" v-if="openabstencoes"></modal-abstencoes>
    <modal-editar-eleicoes
      :eleicao="eleicaoEditar"
      v-on:refreshComponent="getAllEleicoes"
      :key="updComponentes"
    ></modal-editar-eleicoes>
    <modal-cadastrar-eleicoes v-on:refreshComponent="getAllEleicoes" :key="updComponentes+1"></modal-cadastrar-eleicoes>
    <modal-candidatos :key="updComponentes+2" :eleicao="eleicaoDetalhes"></modal-candidatos>
    <modal-usuarios-eleicao :eleicao="eleicaoDetalhes" :key="updComponentes+3" v-if="openusuarioeleicao"></modal-usuarios-eleicao>
    <modal-justificativa v-on:fetch="getAllEleicoes" :eleicao="eleicaojustificativa" :key="updComponentes+5"></modal-justificativa>
    <div>
      <h3 class="h3-responsive module-header">Gerenciar Eleições</h3>
    </div>
    <div class="card">
      <div class="card-body">
        <button
          id="modalActivate"
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          @click="newEleicao"
          data-target="#exampleModalPreview"
        >
          <i class="fa fa-plus"></i>&nbsp;
          Nova Eleição
        </button>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Descrição</th>
                <th>Situação</th>
                <th>Data Inicial</th>
                <th>Data Final</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
                <tr v-if="isLoading">
                    <td colspan="6" class="text-center">
                        <Loading />
                    </td>
                </tr>
               <tr v-if="eleicoes.length == 0 && !isLoading">
                  <td colspan="6" class="text-center">
                      Nenhum registro encontrado
                  </td>
                </tr>
              <tr v-for="(eleicao, index) in eleicoes" :key="index">
                <td>{{++index}}</td>
                <td>{{eleicao.strdescricao}}</td>
                <td>
                  <span v-html="$options.filters.situacao(eleicao.intsituacao)"></span>
                </td>
                <td>{{eleicao.dtainicial | dateFull_br}}</td>
                <td>{{eleicao.dtafinal | dateFull_br}}</td>
                
                <td>
                  <a
                    v-if="eleicao.intsituacao == 1"
                    class="btn-floating z-depth-0 btn-sm btn-primary"
                    data-tooltip-positions="top"
                    data-tooltip="Gerenciar Eleitores"
                    @click="usuariosVotantes(eleicao)"
                    data-toggle="modal"
                    data-target="#UsuariosEleicao"
                  >
                    <i class="fa fa-users" aria-hidden="true"></i>
                  </a>
                  &nbsp;
                  <a
                    v-if="eleicao.intsituacao == 1"
                    class="btn-floating z-depth-0 btn-sm btn-primary"
                    data-tooltip-positions="top"
                    data-tooltip="Gerenciar Candidatos"
                    @click="setCandidato(eleicao)"
                    data-toggle="modal"
                    data-target="#modalCandidatos"
                  >
                    <i class="fa fa-id-card" aria-hidden="true"></i>
                  </a>
                  &nbsp;
                  <a
                    v-if="eleicao.intsituacao == 1"
                    class="btn-floating z-depth-0 btn-sm btn-primary"
                    data-toggle="modal"
                    data-tooltip-positions="top"
                    data-tooltip="Editar Eleição"
                    data-target="#editarEleicao"
                    @click="editarEleicao(eleicao)"
                  >
                    <i class="fa fa-edit" aria-hidden="true"></i>
                  </a>
                  &nbsp;
                  <a
                    class="btn-floating z-depth-0 btn-sm btn-primary"
                    data-toggle="modal"
                    data-tooltip-positions="top"
                    data-tooltip="Detalhes da Eleição"
                    data-target="#modaldetalhes"
                    @click="showEleicao(eleicao)"
                  >
                    <i class="fa fa-info" aria-hidden="true"></i>
                  </a>
                  &nbsp;
                  <a
                    class="btn-floating z-depth-0 btn-sm btn-primary btn-success"
                    v-if="eleicao.intsituacao == 4"
                    data-toggle="modal"
                    data-tooltip-positions="top"
                    data-tooltip="Relatório de Eleitores"
                    data-target="#modalabstencao"
                    @click="showAbstencoes(eleicao)"
                  >
                    <i class="fas fa-file-pdf" aria-hidden="true"></i>
                  </a>

                  &nbsp;
                  <a
                    data-tooltip-positions="top"
                    data-tooltip="Finalizar Eleição"
                    v-if="eleicao.intsituacao != 1 && eleicao.intsituacao != 4 "
                    class="btn-floating z-depth-0 btn-sm btn-danger"
                    @click.prevent="finalizaEleicao(eleicao)"
                  >
                    <i class="fa fa-stop" aria-hidden="true"></i>
                  </a>
                  &nbsp;
                  <a
                    data-toggle="modal"
                    data-target="#modaljustificativa"
                    data-tooltip-positions="top"
                    data-tooltip="Justificar Desempate da Eleição"
                    v-if="eleicao.intsituacao == 4 && eleicao.boolempate == true && eleicao.boolresultado == 0"
                    class="btn-floating z-depth-0 btn-sm secondary-color-dark"
                    @click.prevent="showFormJustificativa(eleicao)"
                  >
                    <i class="fas fa-exclamation-triangle"></i>
                  </a>
                  &nbsp;
                  <a
                    data-tooltip-positions="top"
                    data-tooltip="Publicar Resultado da Eleição"
                    v-if="eleicao.intsituacao == 4 && eleicao.boolresultado == 0"
                    class="btn-floating z-depth-0 btn-sm btn-danger"
                    @click.prevent="publicarResultadoEleicao(eleicao)"
                  >
                    <i class="fas fa-eye"></i>
                  </a>
                  &nbsp;
                  <a
                    data-tooltip-positions="top"
                    data-tooltip="Iniciar Eleição"
                    v-if="eleicao.intsituacao == 1"
                    class="btn-floating z-depth-0 btn-sm btn-success"
                    @click.prevent="iniciaEleicao(eleicao)"
                  >
                    <i class="fas fa-play" aria-hidden="true"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDetalhes from "./ModalDetalhes";
import ModalAbstencoes from "./ModalAbstencoes";
import ModalEditarEleicoes from "./ModalEditarEleicoes";
import ModalCadastrarEleicoes from "./ModalCadastrarEleicoes";
import ModalCandidatos from "./../candidatos/Candidatos";
import ModalUsuariosEleicao from "./../usuarios/ModalUsuariosEleicao";
import ModalJustificativa from './ModalJustificativa'

import Mixins from "../../mixins";
import Loading from '../misc/Loading';
import moment from 'moment';
export default {
  components: {
    Loading,
    ModalDetalhes,
    ModalEditarEleicoes,
    ModalCadastrarEleicoes,
    ModalCandidatos,
    ModalUsuariosEleicao,
    ModalAbstencoes,
    ModalJustificativa
  },
  mixins: [Mixins],
  data() {
    return {
      eleicaojustificativa:{},
      openJustificativa: false,
      eleicaoDetalhes: {},
      eleitoresAbstencao:{},
      eleicaoEditar: {},
      eleicoes: [],
      updComponentes: 0,
      opendetalhes:false,
      openusuarioeleicao:false,
      isLoading: true,
      openabstencoes:false
      
    };
  },
  methods: {
    finalizaEleicao(eleicao) {
      swal
        .fire({
          title: "Atenção",
          text: "Deseja finalizar a eleição " + eleicao.strdescricao + "?",
          type:"warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: false
        })
        .then(result => {
            if(result.value){
                axios.put(`/eleicoes/finalizar/${eleicao.streleicaouuid}`,{})
                .then(res => {
                        toastr.success("Eleição finalizada com Sucesso");
                        this.getAllEleicoes();
                }, err => {
                        toastr.error(err.response.data.mensagem);
                });
            }
            
        });
    },
    iniciaEleicao (eleicao) {
        swal
        .fire({
          title: "Atenção",
          text: "Deseja iniciar a eleição " + eleicao.strdescricao + "?",
          type:"warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: false
        })
        .then(async result => {
            if (result.value) {
                   await axios.put(`/eleicoes/iniciar/${eleicao.streleicaouuid}`, {})
                    .then(res=>{
                        toastr.success("Eleição iniciada com Sucesso");
                        this.getAllEleicoes();
                    }, err => {
                        toastr.error(err.response.data.mensagem);
                    });
            }
        });
    },
    usuariosVotantes(eleicao) {
        this.eleicaoDetalhes =  {...eleicao}
        this.openusuarioeleicao = true
        this.forceUpdate();
    },
    setCandidato(eleicao) {
      this.forceUpdate();
      Object.assign(this.eleicaoDetalhes, eleicao);
    },
    newEleicao() {
      this.forceUpdate();
    },
    forceUpdate() {
      this.updComponentes += 1;
    },
    async getAllEleicoes() {
      await axios.get("/eleicoes/listar").then(res => {
        this.eleicoes = res.data.eleicoes;
        this.isLoading = false;
      }).catch((err)=>{
          console.log('erro inesperado')
      });
    },
    showEleicao(eleicao) {
        Object.assign(this.eleicaoDetalhes, eleicao);
        this.opendetalhes = true;
        this.forceUpdate();
    },
    showAbstencoes(eleicao) {
        this.openabstencoes = true;
        Object.assign(this.eleitoresAbstencao, eleicao);
        this.forceUpdate();
    },
    showFormJustificativa(eleicao) {
        this.openJustificativa = true;
        Object.assign(this.eleicaojustificativa, eleicao);
        this.forceUpdate();
    },
    editarEleicao(eleicao) {
      Object.assign(this.eleicaoEditar, eleicao);

      this.forceUpdate();
    },
    publicarResultadoEleicao(eleicao){
        swal
        .fire({
          title: 'Atenção',
          text: 'Deseja publicar o resultado da eleição ?',
          type: 'question',
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: false
        })
        .then(result => {
          if (result.value) {
            axios.put(`/eleicoes/publicar/${eleicao.streleicaouuid}`, eleicao).then(
              res => {
                  toastr.success("Resultado Publicado");
                  this.getAllEleicoes();
              },
              err => {
                if(err.response.data.mensagem) toastr.error(err.response.data.mensagem);
              }
            );
          }
        });
       
    }
  },
  mounted() {
    this.getAllEleicoes();
  }
};
</script>
