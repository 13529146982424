<template>
    <div>
        <button
          type="button"
          @click="()=>{this.$emit('handle')}"
          :disabled="isLoading"
          :class="`btn-loading ${buttonClass}`">
            <i v-if="!isLoading" :class="`${icon} mr-2`"></i>
            <div v-else class="spinner-border mr-2" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <span>{{label}}</span>
        </button>
    </div>
</template>

<script>
export default {
  props: ['label', 'icon', 'isLoading', 'className', 'handle', 'buttonClass'],
  methods: {
    eventHandle () {
    }
  }
}
</script>

<style scoped>
.btn-loading{
    display: flex;
    align-items: center;
    justify-content: center;
}
.spinner-border{
    width:1.5rem;
    height:1.5rem;
}
</style>
