<template>
  <div>
    <div>
      <h3 class="h3-responsive module-header">Gerenciar Usuários</h3>
    </div>
    <div class="card">
      <div class="card-body">
        <button class="btn btn-primary" data-toggle="modal" data-target="#cadastrarUsuario">
          <i class="fa fa-plus"></i>&nbsp;Novo Usuário
        </button>
        <div class="table-responsive">
          <table class="table table-striped" id="users">
            <thead>
              <tr>
                <th>#</th>
                <th>Usuário</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Situação</th>
                <th>Ação</th>
              </tr>
            </thead>

            <tbody>
              <tr class="text-center" v-if="isLoading">
                  <td colspan="7">
                      <Loading />
                  </td>
              </tr>
              <tr class="text-center" v-if="usersOrd.length == 0 && !isLoading">
                  <td colspan="7">Nenhum registro encontrado</td> 
              </tr>
              <tr v-for="(u, index) in usersOrd" :key="index">
                <td>{{++index}}</td>
                <td><strong>{{u.strusuario}}</strong></td>
                <td>{{u.strnome}} <br/>
                    <small><em>({{u.intidperfil | perfil}})</em></small>
                </td>
                <td>{{u.stremail}}</td>
                <td>{{u.strcelular}}</td>
                <td>
                  <div v-if="!u.idfuncionario" v-html="$options.filters.isAtivo(u.boolativo)"></div>
                </td>
                <td>
                  <a
                    data-tooltip-positions="top"
                    data-tooltip="Editar Usuário"
                    @click="editarUsuario(u)"
                    v-if="!u.idfuncionario"
                    class="btn-floating z-depth-0 btn-sm btn-primary"
                    data-toggle="modal"
                    data-target="#editarUsuario"
                  >
                    <i class="fa fa-edit" aria-hidden="true"></i>
                  </a>

                  <a
                    :disabled="true"
                    v-if="u.idfuncionario"
                    class="btn-floating z-depth-0 btn-sm btn-danger"
                  >
                    <i class="fa fa-lock" aria-hidden="true"></i>
                  </a>

                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <modal-editar-usuario
      :key="updateComponent+1"
      :usuario="userEdit"
      v-on:refreshComponent="getAllUsers()"
    ></modal-editar-usuario>
    <modal-cadastrar-usuario :key="updateComponent+2" v-on:refreshComponent="getAllUsers()"></modal-cadastrar-usuario>
  </div>
</template>

<script>
import $ from "jquery";

import ModalCadastrarUsuario from "./ModalCadastrarUsuario";
import ModalEditarUsuario from "./ModalEditarUsuario";
import Loading from './../misc/Loading'
import _ from "lodash";
require('datatables.net')
export default {
  name: "usuarios",
  components: {
    ModalCadastrarUsuario,
    ModalEditarUsuario,
    Loading
  },
  data() {
    return {
      updateComponent: 0,
      usuarios: [],
      user: {},
      userEdit: {},
      openModalEdit: false,
      isLoading: true
    };
  },
  computed: {
    usersOrd() {

      return _.orderBy(this.usuarios, ['strnome','stremail'], [true, false]);
    }
  },
  methods: {
    forceComponent() {
      this.updateComponent += 1;
    },
    async getAllUsers() {
      this.forceComponent();
      let hasLDAP = process.env.VUE_APP_USER_LDAP;
      await axios.get("/usuarios/listar").then(
        res => {
          this.usuarios = res.data.usuarios;
        },
        err => console.log(err)
      );

      if (hasLDAP == "Y") {
        // await axios.get("/usuarios/listar/ldap").then(res => {
        //     let usuariosLdap = res.data.usuarios;
        //     this.usuarios = [...this.usuarios, ...usuariosLdap]; 
        // });
      }
      
      this.isLoading = false;
    },
    setUser() {
      this.forceComponent();
    },
    editarUsuario(usuario) {
      this.forceComponent();
      Object.assign(this.userEdit, usuario);
      this.openModalEdit = true;
    }
  },
  mounted() {
    this.getAllUsers();
  },
  filters: {
    upper(value) {
      if (value) {
        return value.toUpperCase();
      }
    },
    perfil (value) {
        const isAdmin = process.env.VUE_APP_PERMISSAO_ADMINISTRADOR
        const isComissao = process.env.VUE_APP_PERMISSAO_COMISSAO
        const isEleitor = process.env.VUE_APP_PERMISSAO_ELEITOR

        switch (value) {
            case parseInt(isAdmin):
                return 'Administrador'
            break;
            
            case parseInt(isComissao):
                return 'Comissão'
            break;

            case parseInt(isEleitor):
                return 'Eleitor'
            break;
        
            default:
                break;
        }
    },
    isAtivo(value) {
      if (!value)
        return "<span class='badge badge-custom badge-danger'>Inativo</span>";
      return "<span class='badge badge-custom badge-success'>Ativo</span>";
    }
  }
};
</script>
<style>
</style>