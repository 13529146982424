<template>
  <div>
    <modal-alterar-senha></modal-alterar-senha>
    <header>
      <nav
        class="navbar fixed-top navbar-expand-lg scrolling-navbar double-nav navbar-dark nav-color"
      >
          <span class="white-text">
              <img :src="logo" alt="" width="100px;" >
          </span>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent-333"
          aria-controls="navbarSupportedContent-333"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent-333">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" v-if="regraEleicao">
                <router-link class="nav-link" :to="{ name: 'votacao'}">Votação</router-link>
            </li>
            <li class="nav-item" v-if="regraEleicao">
              <router-link class="nav-link" :to="{ name: 'eleicoes'}">Eleições</router-link>
            </li>
            <!-- <li class="nav-item" v-if="regraUsuario">
              <router-link class="nav-link" v-if="autenticacao == 'ldap'" :to="{ name: 'usuarios'}">Usuários</router-link>
            </li> -->
          </ul>
          <ul class="navbar-nav ml-auto nav-flex-icons">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdownMenuLink-333"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-user mr-1 pr-1"></i>
                &nbsp;{{greatting}}
              </a>
              <div
                class="dropdown-menu dropdown-menu-lg-right"
                aria-labelledby="navbarDropdownMenuLink-333"
              >
		            <a class="dropdown-item fancybox">
                  <i class="fas fa-question-circle mr-1 pr-1"></i>
                  Manual
                </a>

                <a class="dropdown-item" href="#" @click="logout">
                  <i class="fa fa-power-off mr-1 pr-1"></i>
                  Sair
                </a>
                <a v-if="autenticacao != 'ldap'" class="dropdown-item" data-toggle="modal" data-target="#AlterarSenha" href="#">
                  <i class="fa fa-key mr-1 pr-1"></i>
                  Alterar senha
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>
import jwtdecode from "jwt-decode";
import profile from "../../profile";
import ModalAlterarSenha from "../usuarios/ModalAlterarSenha";
export default {
  components: {
    ModalAlterarSenha
  },
  name: "Header",
mounted() {
    $(document).ready(function(){
	var manual_link = '';
      if(profile().isEleitor){
	manual_link = "https://docs.google.com/viewer?url=https://defensoria.ma.def.br/eleicoes/files/manual_eleicao_eleitor.pdf&embedded=true"
      } else {
	manual_link = "https://docs.google.com/viewer?url=https://defensoria.ma.def.br/eleicoes/files/manual_eleicao.pdf&embedded=true"
      }
        $(".fancybox").fancybox({
              beforeLoad: function(){
              },
              afterClose: function(){
              },
              src: manual_link,
              type: 'iframe'
          });
    });
  },
  methods: {
    logout() { 
      swal.fire({
          title: "Aviso",
          text: "Você tem certeza que deseja sair do sistema ?",
          type: "question",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: false
        })
        .then(result => {
          if (result.value) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            location.href = process.env.VUE_APP_BASE_ROUTE;
            // location.reload(true);
          }
        });
    }
  },
  computed: {
    logo() {
        return process.env.VUE_APP_IMG_HEADER;
    },
    regraVotacao() {
        return profile().isComissao || profile().isEleitor;
    },
    regraEleicao() {
        return profile().isComissao;
    },
    regraUsuario() {
        return profile().isAdministrador;
    },
    greatting() {
        const user = JSON.parse(localStorage.getItem('user'));
        if(!user) return ;
        let fullname = (user.autenticacao == 'ldap') ? user.data.cn : user.data.strnome,
        username = fullname.split(" ")[0];
        return `Bem vindo (a), ${username}`;
    },
    autenticacao(){
        const user = JSON.parse(localStorage.getItem('user'));
        if(!user) return ;
        return user.autenticacao;
    }
  }
};
</script>
<style scoped>
nav > a {
  color: #000 !important;
}
.nav-color {
  background-color: #505050;
}
.router-link-active {
  background-color: rgba(255, 255, 255, 0.1);
}

.router-link-active:first-child {
  color: #fff !important;
  background-color: inherit;
  /* background-color: rgba(255,255,255,.1); */
}

.dropdown-item:hover {
  background-color: #999999 !important;
}
</style>
