<template>
  <div>
    <div>
      <div
        class="modal fade right"
        id="modalCandidatos"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalPreviewLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalPreviewLabel">
                Eleição:
                <strong>{{eleicao.strdescricao}}</strong> > Gerenciar Candidatos
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <Loading v-if="isLoading"/>
              <div
                v-if="candidatos.length > 0"
                class="row"
                id="cards-candidatos"
                style="max-height:400px; overflow:auto;"
              >
                <div
                  class="col-md-4 col-lg-3 col-sm-12 text-center d-flex justify-content-center"
                  v-for="(c, index) in candidatos"
                  :key="index"
                  style="magin-bottom:2% !important"
                >
                  <div
                    class="card testimonial-card"
                    style="width:150px; height:200px; margin-bottom:5%; margin-top:5%"
                  >
                    <div
                      class="card-up lighten-2 text-center align-center"
                      v-bind:style="{backgroundImage: 'url('+baseUrl+ c.tblfoto.strnomearquivo + ')'}"
                      style="background-size:cover; background-repeat:no-repeat; background-position:center; background-color:#fff;"
                    >
                      <div
                        v-if="c.strnumero"
                        style="width:30px;height:30px; position:absolute; right:5px;top:5px; text-align:center; line-height:30px; overflow:hidden"
                        class="rounded-circle blue white-text"
                      >{{c.strnumero}}</div>
                    </div>

                    <div class="card-body" style="padding:0px !important">
                      <span class="card-title mt-1">{{c.strnome}}</span>
                      <br />
                      <button class="btn z-depth-0 btn-sm btn-danger" @click="delCandidato(c)">
                        <i class="fa fa-trash"></i>&nbsp;Remover
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-8 col-lg-8">
                  <label for="strnome">
                    Nome
                    <span class="required-label">*</span>
                  </label>
                  <input id="strnome" ref="strnome" type="text" class="form-control" v-model="candidato.strnome" />
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <label for="strnumero">Número</label>
                  <input
                    id="strnumero"
                    type="number"
                    class="form-control"
                    v-model="candidato.strnumero"
                  />
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12" v-if="imgpath == ''">
                  <label for="intfotoid">
                    Imagem
                    <span class="required-label">*</span>
                  </label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input
                        type="file"
                        @change="sendFile"
                        ref="ImgCandidato"
                        class="custom-file-input"
                        id="intfotoid"
                      />
                      <label
                        data-browse="Procurar"
                        class="custom-file-label"
                        for="intfotoid"
                      >Selecione o arquivo</label>
                    </div>
                  </div>
                </div>
                <div
                  class="col-sm-12 col-md-12 col-lg-12 text-center"
                  style="padding-top:1%"
                  v-if="imgpath != '' "
                >
                  <img
                    :src="baseUrl+imgpath"
                    width="200px; height:auto"
                    class="img-fluid img-thumbnail"
                    alt
                  />
                  <br />
                  <a href @click.prevent="changeImg">Alterar Foto</a>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <button type="button" :disabled="clicked" class="btn btn-primary" @click="saveCandidato()">
                  <i class="fa fa-save mr-1 pr-1 "></i> Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from "./../../mixins";
import * as helpers from "../../helpers";
import Loading from '../misc/Loading';

export default {
    components:{
        Loading
    },
  props: ["eleicao"],
  mixins: [Mixin],
  data() {
    return {
      imgpath: "",
      candidato: {},
      candidatos: {},
      files: [],
      clicked:false,
      isLoading:true
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    }
  },
  methods: {
    changeImg() {
      this.imgpath = "";
    },
    sendFile(event) {
      let fileExtension = ['image/jpeg', 'image/jpg', 'image/png'];

      this.files = this.$refs.ImgCandidato.files[0];
      if(!fileExtension.includes(this.files.type)) {
          toastr.error('Formato de imagem inválido! Por favor, certifique-se que a foto esteja no formato JPG, JPEG ou PNG');
          return false;
      }
      if(this.files.size > (1024 * 1024)){
          toastr.error('Tamanho de imagem inválido, por favor insira uma imagem menos de 1mb');
          return false;
      }
      
      var formData = new FormData();
      formData.append("strarquivo", this.files);
         
      axios
        .post("/uploads/cadastrar", formData, {
          header: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(
          res => {
            this.imgpath = res.data.registro.strnomearquivo;
            this.candidato.intfotoid = res.data.registro.id;
          },
          err => toastr.error(err.response.data.erro)
        );
    },
    async getAllCandidatos() {
      if (this.eleicao.id === undefined) return false;
      var url = "/candidatos/eleicao/" + this.eleicao.id;
      await axios.get(url).then(res => {
        this.candidatos = res.data.candidatos;
        this.isLoading = false
      });
    },
    saveCandidato() {
      this.candidato.inteleicaoid = this.eleicao.id;
      var candidato = this.candidato;
    
      if (helpers.isEmpty(candidato.strnome)) {
        toastr.error("O nome do candidato é obrigatório");
        this.$refs.strnome.focus();
        return false;
      }

      if (candidato.intfotoid === undefined) {
        toastr.error("A foto do candidato é obrigatória");
        return false;
      }
      this.candidato.strnumero = this.candidato.strnumero ? this.candidato.strnumero:null; 
      this.clicked = true;
      axios.post("/candidatos/cadastrar", this.candidato).then(
        res => {
          toastr.success("Candidato cadastrado com sucesso");
          this.clicked = false;
          var mydiv = $("#cards-candidatos");
          mydiv.scrollTop(mydiv.prop("scrollHeight"));
          this.candidato.strnumero = "";
          this.candidato.strnome = "";
          this.candidato.intfotoid = undefined;
          this.imgpath = "";
          this.getAllCandidatos();
        },
        err => {
            this.clicked = false;
            toastr.error(err.response.data.mensagem)
        }
      ).catch((err)=>console.log(err));
    },
    delCandidato(candidato) {
      swal
        .fire({
          text: "Deseja remover o candidato " + candidato.strnome + "?",
          title: "Remover Candidato",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: false
        })
        .then(result => {
          if (result.value) {
            axios.delete("/candidatos/apagar/" + candidato.id).then(res => {
              swal.fire(
                "Removido!",
                "Candidato removido",
                "success"
              );
              this.getAllCandidatos();
            });
          }
        });
    }
  },
  mounted() {
    this.getAllCandidatos();
  }
};
</script>
<style>
</style>
