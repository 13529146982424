<template>
  <div>
    <div class="container">
      <div
        class="modal fade right"
        id="modalComprovante"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalPreviewLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalPreviewLabel">
                <i class="fas fa-vote-yea mr-1 pr-1"></i>
                Comprovante de Votação
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label for="token">Insira o código que você recebeu por email</label>
                  <input type="text" class="form-control" name="token" id="token" v-model="token" @keypress.enter="confirmarVotacao" />
                </div>
                <div
                  class="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center"
                  v-if="areacomprovante"
                >
                  <div class="area-comprovante mt-2">
                    <div class="list-group-item indigo lighten-5 text-center" style="text-transform:uppercase">
                        Comprovante de Votação da {{eleicao.strdescricao}}<br/>
                        <strong>
                            <small>Data da votação:{{this.realizacao | dateFull_br}}</small>
                        </strong>
                    </div>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="font-weight:bold">
                                    Número
                                </th>
                                <th style="font-weight:bold">
                                    Candidato
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(c, index) in resultComprovante" :key="index">
                                
                                <td style="width:30px; line-heigth:50px;" class="text-center">{{c.tblcandidato.strnumero | numeroCandidato}}</td>
                                <td class="d-flex align-items-center" style="font-size:12pt">
                                    <img v-if="c.tblcandidato.strnumero ==0" class="img-fluid rounded-circle float-left border mr-3" style="width:50px; height:50px;" :src="iconVotoBranco" > 
                                    <img v-else class="img-fluid rounded-circle float-left border mr-3" style="width:50px; height:50px;" :src="c.strcandidatouuid | showImg(candidatosFotos)" >
                                    {{c.tblcandidato.strnome}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <button v-if="!this.areacomprovante" type="button" class="btn btn-primary" @click="confirmarVotacao">
                  <i class="fa fa-check mr-1 pr-1"></i>
                  Conferir Voto
                </button>
                <button v-if="this.areacomprovante" type="button" data-dismiss="modal" class="btn btn-danger">
                    Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as helpers from '../../helpers'
import Mixin from "./../../mixins";
export default {
  mixins: [Mixin],
  props:['eleicao'],
  data() {
    return {
      token: "",
      areacomprovante: false,
      resultComprovante: [],
      realizacao:'',
      candidatosFotos:[]
    };
  },
  computed:{
    baseUrl() {
        return process.env.VUE_APP_BASE_URL;
    },
    iconVotoBranco(){
        return require("./../../../public/img/empty.png");
    }
  },
  methods: {
    async getCandidatosEleicao (eleicaoid) {
        await axios.get(`/candidatos/eleicao/${eleicaoid}`).then(res=>{
            let candidatosDB = res.data.candidatos;
            candidatosDB.forEach(element => {
                this.candidatosFotos.push({
                    'strcandidatouuid' : element.strcandidatouuid,
                    'strfoto':element.tblfoto.strnomearquivo
                });
            });
        });
    },
    confirmarVotacao() {
      if (helpers.isEmpty(this.token)) {
        toastr.error("Por favor, insira o token");
        return false;
      }
        let dados = { 
          strtokenuuid: this.token.trim(), 
          inteleicaoid:this.eleicao.id  
        };
      axios
        .post("/votos/comprovante", dados)
        .then(
          res => {
            this.resultComprovante = res.data.comprovante;
            let qtselecionaveis = this.eleicao.intqtdcandidatosselecionaveis;
            let qtresults = this.resultComprovante.length;
            if(qtselecionaveis > qtresults){
                for (let i = qtresults; i < qtselecionaveis; i++) {
                    this.resultComprovante.push({
                        tblcandidato:{
                            strnumero:0,
                            strnome:'Voto em branco'
                        }
                    })
                }
            };  
            this.areacomprovante = true;
            this.realizacao = (qtresults > 0)? res.data.comprovante[0].createdAt : res.data.branco[0].createdAt;
            
          }, 
          err => {
            //   toastr.error('Por favor, insira um token válido');
              toastr.error(err.response.data.mensagem);
            }
          )
        .catch(err => {
          console.log('error', err);
        });

    }
  },
  mounted() {
      this.getCandidatosEleicao (this.eleicao.id)
  },
  filters:{
    showImg (uuid, candidatosFotos) {
          let imgpath = '';
          candidatosFotos.forEach(el=>{
             if(el.strcandidatouuid == uuid) {
                 imgpath = el.strfoto
              }
          });
          return process.env.VUE_APP_BASE_URL+imgpath
    },
    numeroCandidato (value) {
        return value ? value: 'Sem número';
    }
  }
};
</script>
<style>
.area-comprovante {
  width: 100% !important;
}
</style>