<template>
  <div>
  <Header/>
  <div class="content">
    <div class="alert alert-danger" v-if="isOffline" >
        <strong>Você está offline!</strong>

        Por favor, certifique-se que seu dispositivo esteja conectado à internet para utilizar o sistema</div>
    <router-view></router-view>  
  </div>
  </div>
</template>

<script>

import Header from './template/Header.vue'

export default {
 methods:{
     listEleicoes() {
        
     }
 },
 mounted(){
     this.listEleicoes();
 },
 components: {
    Header
}
}
</script>

<style>

</style>