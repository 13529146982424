<template>
  <div>
    <div class="modal fade" id="editarUsuario" tabindex="-1">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title w-300 font-weight-bold">Editar Usuário</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <form>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="strnome2">Nome<span class="required-label">*</span></label>
                <input type="text" ref="ref_strnome" id="strnome2" class="form-control" v-model="dados.strnome" maxlength="200" />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="stremail2">Email<span class="required-label">*</span></label>
                <input type="text" autocomplete="off" class="form-control" ref="ref_stremail2" id="stremail2" v-model="dados.stremail" maxlength="50" />
              </div>
              
              <div class="col-sm-12 col-md-4 col-lg-4">
                <label for="strusuario2">Usuário<span class="required-label">*</span></label>
                <input type="text" minlength="5" class="form-control" ref="ref_strusuario2" id="strusuario2" v-model="dados.strusuario" maxlength="15" readonly/>
              </div>

              <div class="col-sm-12 col-md-4 col-lg-4">
                    <label for="intperfil_id">Tipo de Usuário<span class="required-label">*</span></label>
                    <select id="intperfil_id" ref="ref_intperfil_id" class="browser-default custom-select" v-model="dados.intidperfil">
                        <option value="0">Selecione</option>
                        <option :value="perfil.administrador">Administrador</option>
                        <option :value="perfil.comissao">Comissão</option>
                        <option :value="perfil.eleitor">Eleitor</option>
                    </select>
             </div>

              <div class="col-sm-12 col-md-4 col-lg-4">
                <label for="strcelular2">Telefone<span class="required-label">*</span></label>
                <masked-input placeholder-char=" " ref="ref_strcelular2"  id="strcelular2" class="form-control" v-model="dados.strcelular" maxlength="12" mask="(11) 11111-1111" />
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="strsenha2">Senha</label>
                <input type="password" class="form-control" ref="ref_strsenha2" id="strsenha2" v-model="dados.strsenha" autocomplete="off" />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="strcsenha2">Confirmar Senha</label>
                <input type="password" class="form-control" id="strcsenha2" v-model="dados.strcsenha" autocomplete="off" />
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" v-if="!dados.boolativo" @click="ativarUsuario(dados)" class="btn btn-success" ><i class="fa fa-unlock mr-1 pr-1 "></i> Ativar </button>
            <button type="button" v-if="dados.boolativo" @click="desativarUsuario(dados)" class="btn btn-danger" ><i class="fa fa-lock mr-1 pr-1 "></i> Desativar </button>
            <button class="btn btn-primary" type="button" @click="editarUsuario(dados)">
              <i class="fa fa-save mr-1 pr-1 "></i> Salvar
            </button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from "vuex";
import * as helpers from './../../helpers';
import MaskedInput from 'vue-masked-input'

export default {
  components:{
      MaskedInput
  },
  props: ["usuario"],
  data() {
    return {
      dados: {}
    };
  },
  computed:{
      perfil () {
         return {
             administrador:process.env.VUE_APP_PERMISSAO_ADMINISTRADOR,
             comissao:process.env.VUE_APP_PERMISSAO_COMISSAO,
             eleitor:process.env.VUE_APP_PERMISSAO_ELEITOR
         }
      }
  },
  methods: {
    ...mapActions(["fetchAllUsers"]),
    desativarUsuario(usuario) {
      swal.fire({
          title: 'Desativar Usuário',
          text: "Deseja desativar o usuário " + usuario.strnome + "?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: false
        })
        .then(result => {
          if (result.value) {
            axios.delete("/usuarios/apagar/" + usuario.id).then(res => {
              swal.fire(
                "Desativado!",
                "Usuário desativado",
                "success"
              );
              this.$emit('refreshComponent');
              $('.modal').modal('hide');
            });
          }
        });
    },
    ativarUsuario(usuario) {
      swal
        .fire({
          text: "Deseja ativar o usuário " + usuario.strnome + "?",
          title: "Ativar Usuário",
          type: "question",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: false
        })
        .then(result => {
          if (result.value) {
            axios.put("/usuarios/recuperar/" + usuario.id).then(res => {
              swal.fire(
                "Ativado!",
                "Usuário "+usuario.strusuario+' ativado',
                "success"
              );
              this.$emit('refreshComponent');
              $('.modal').modal('hide');
            });
          }
        });
    },
    editarUsuario(usuario) {
      if (helpers.isEmpty(usuario.strnome)) {
        toastr.error("Nome do usuário é obrigatório");
        this.$refs.ref_strnome.focus();
        return false;
      }
      
      if (helpers.isEmpty(usuario.strusuario)) {
        toastr.error("O login do usuário é obrigatório");
        this.$refs.ref_strusuario2.focus();
        return false;
      }
      if(usuario.strusuario.length < 5){
        toastr.error("O login do usuário deve conter no mínimo 5 caracteres");
        this.$refs.ref_strusuario2.focus();
        return false;
      }
      if (helpers.isEmpty(usuario.stremail) || !helpers.isEmail(usuario.stremail)) {
          toastr.error("Insira um email válido");
          this.$refs.ref_stremail2.focus();
          return false;
      }
      if(helpers.isEmpty(usuario.intidperfil) || usuario.intidperfil ==0) {
          toastr.error("O perfil é obrigatório");
          this.$refs.ref_intperfil_id.focus();
          return false;
      }
      if(helpers.isEmpty(usuario.strcelular)) {
          toastr.error("O telefone é obrigatório");
          $('#strcelular2').focus();
          return false;
      }
      
      if(!helpers.isEmpty(usuario.strsenha) || !helpers.isEmpty(usuario.strcsenha)) {
        if (usuario.strsenha != usuario.strcsenha) {
          toastr.error("As senhas não coincidem");
          return false;
        }
      }

      axios.put(`usuarios/alterar/${usuario.id}`, usuario).then(
        res => {
          this.usuario.strsenha = undefined
          this.usuario.strcsenha = undefined
          toastr.success(res.data.mensagem)
          this.$emit('refreshComponent');
          $('.modal').modal('hide');
        },
        err => toastr.error(err.response.data.mensagem)
      );
    }
  },
  mounted() {
    this.dados = this.usuario;
  }
};
</script>
