<template>
  <div>
    <div class="modal fade" id="UsuariosEleicao" tabindex="-1">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">
              Eleição:
              <span class="font-weight-bold">{{eleicao.strdescricao}}</span> > Adicionar votantes
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <small
                    style="font-size:11pt; text-transform:uppercase; margin-bottom:2%"
                  >Eleitores máximos permitidos&nbsp;({{eleicao.intqtdeleitores}})</small>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <h3>Disponíveis</h3>
                  <ul class="list-group" style="height:400px; overflow:auto">
                    <li class="list-group-item li-usuarios">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="selecionartodos"
                          v-model="selecionartodos"
                          @click="checkAllEleitores"
                        />

                        <label class="custom-control-label" for="selecionartodos">
                          <span>Selecione todos</span>
                        </label>
                      </div>
                    </li>
                    <li v-if="isLoading" class="list-group-item li-usuarios">
                        <Loading/>
                    </li>
                    <li
                      v-if="usuarios.length == 0"
                      class="list-group-item li-usuarios"
                    >Nenhum usuário disponível</li>
                    <li
                      class="list-group-item li-usuarios"
                      v-for="(u, index) in usuarios"
                      :key="index"
                    >
                      <div class="md-v-line"></div>
                      <div class="row">
                        <div class="col-lg-1">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input chk-users"
                              :id="u | tipoUsuario"
                              :value="JSON.stringify(u)"
                            />
                            <label class="custom-control-label" :for="u | tipoUsuario"></label>
                          </div>
                        </div>
                        <div class="col-lg-10" @click="setUser(u)">
                          <i class="fas fa-user-check mr-1 pr-1" style="color: #00b33c"></i>
                            <span v-if="u.strnome">{{u.strnome}}</span>
                            <span v-else>{{u.strusuario | eleitorNome(usuarioNome)}}</span>
                        </div>
                      </div>
                    </li>
                    <!-- <li></li> -->
                  </ul>
                  <button class="btn btn-sm btn-primary" @click="addEleitores()">
                      <i class="fa fa-plus"></i>
                      Adicionar</button>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <h3>Habilitados ({{usuariosEleicao.length}})</h3>

                  <ul class="list-group" style="max-height:400px; overflow:auto">
                    <li
                      v-if="usuariosEleicao.length == 0"
                      class="list-group-item li-usuarios"
                    >Nenhum usuário habilitado</li>
                    <li
                      class="list-group-item li-usuarios"
                      v-for="(u, index) in orderedUsersEleicao"
                      :key="index"
                      @click="removeUsers(u)"
                    >
                      <div class="md-v-line"></div>
                      <i class="fas fa-user-times mr-1 pr-1" style="color:#ff6666"></i>
                      {{u.strusuario | eleitorNome(usuarioNome)}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-primary" @click="saveEleitores">
              <i class="fa fa-save mr-1 pr-1 "></i> &nbsp; Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Loading from '../misc/Loading';
export default {
  props: ["eleicao"],
  components:{
      Loading
  },
  data() {
    return {
      usuarios: [],
      usuariosEleicao: [],
      usersEleitores: [],
      selecionartodos: false,
      strusuariodb: [],
      strnomes:[],
      isLoading:true,
      usuarioNome:[]
    };
  },
  methods: {
    addEleitores() {
      let eleitores = $(".chk-users"), userslist = [], hasSelecionado = 0, qtEleitoresSelecionados = eleitores.length;
      for (let i = 0; i < qtEleitoresSelecionados; i++) {
        
        if (eleitores[i].checked) {
          hasSelecionado++;
          if (this.eleicao.intqtdeleitores == this.usuariosEleicao.length) {
            toastr.error(
              "Limite máximo de vontantes atingido:" +
                this.eleicao.intqtdeleitores
            );
            eleitores[i].checked = false;
            return false;
          }
          
          userslist = $.grep(this.usuarios, function(usuario) {
            return usuario.strusuario != JSON.parse(eleitores[i].value).strusuario;
          });

          this.usuariosEleicao.push(JSON.parse(eleitores[i].value));
          
          this.usuarios = userslist;
        }

        eleitores[i].checked = false;
        $("#selecionartodos")[0].checked = false;
      }
      if (hasSelecionado == 0) {
        toastr.error("Nenhum candidato selecionado");
      }
    },
    async getEleitoresByEleicaoId() {
      await axios.get(`eleitores/eleicao/${this.eleicao.id}`).then(res => {
        this.usuariosEleicao = res.data.eleitores;
        this.usuariosEleicao.db = true;
        this.usuariosEleicao.forEach(el => {
          this.strusuariodb.push(el.strusuario);
        });
      });
    },
    saveEleitores() {
      let newEleitores = [];
      let usuarios = [];
      newEleitores = $.grep(this.usuariosEleicao, function(usuario) {
        return usuario.db != true;
      });
      newEleitores.forEach(element => {
        usuarios.push(element.strusuario);
      });

      let datauser = usuarios.join(",");

      axios
        .post("/eleitores/cadastrar", {
          strusuario: datauser,
          inteleicaoid: this.eleicao.id
        })
        .then(
          res => {
            toastr.success(res.data);
          },
          err => {console.log(err.response)}
        )
        .catch(err => {console.log(err.response)});
    },
    checkAllEleitores() {
      let chkUsuariosDisponiveis = $(".chk-users"),
        chkSelecionarTodos = $("#selecionartodos")[0];

      for (let input of chkUsuariosDisponiveis) {
        if (chkSelecionarTodos.checked) input.checked = true;
        else input.checked = false;
      }
    },
    async getAllUsers() {
      let hasLDAP = process.env.VUE_APP_USER_LDAP;
      if (hasLDAP == "Y") {
        await axios
          .get("/usuarios/listar/ldap")
          .then(res => {
            let usuarios = res.data.usuarios;
            usuarios.forEach(el=>{
                if(!this.strusuariodb.includes(el.strusuario)){
                    this.usuarios = [...this.usuarios, el];
                }
            })
            usuarios.forEach(el=>{
                this.usuarioNome = [...this.usuarioNome, el];
            });
          })
          .catch(err => {});
      }
      await axios
        .get("/usuarios/listar")
        .then(res => {
          let usuariosdb = res.data.usuarios, users = [];
          usuariosdb.forEach(el => {
            if(el.intidperfil === 3) {
                if (!this.strusuariodb.includes(el.strusuario)) {
                    this.usuarios = [...this.usuarios, el];
                }

                this.usuarioNome = [...this.usuarioNome, el];
            }
          });
            
            this.usuarios = _.orderBy(this.usuarios, "strnome");
        })
        .catch(err => {});
      this.isLoading = false;
    },
    setUser(u) {
      var qtEleitores = this.eleicao.intqtdeleitores,
        index = this.usuarios.indexOf(u);
      if (qtEleitores == this.usuariosEleicao.length) {
        toastr.error("Limite máximo de vontantes atingido:" + qtEleitores);
        return false;
      }
      if (index > -1) {
        this.usuarios.splice(index, 1);
      }
      this.usuariosEleicao.push(u);
      this.checkAllEleitores();
    },
    async removeUsers(u) {
      var index = this.usuariosEleicao.indexOf(u);
       
      if (index > -1) {
        this.usuariosEleicao.splice(index, 1);
        if (this.strusuariodb.includes(u.strusuario)) {
          let inteleicaoid = this.eleicao.id
          await axios
            .delete(`/eleitores/apagar/${u.strusuario}/${inteleicaoid}`)
            .then(res => {
                toastr.success(res.data.mensagem)
                this.usuarios.push(u);
              }, err => {
                toastr.error(err.response);
            });
          await this.getEleitoresByEleicaoId()
        }
      }
    }
  },
  computed: {
    isAdmin () {
        return process.env.VUE_APP_PERMISSAO_ADMINISTRADOR
    },
    orderedUsersEleicao () {
      return _.orderBy(this.usuariosEleicao, "strnome");
    }
  },
  mounted() {
    this.getEleitoresByEleicaoId();
    this.getAllUsers();
    this.usuariosEleicao = _.orderBy(this.usuarios, "strnome");
  },
  filters:{
      tipoUsuario(value) {
         return value.id ? 'db'+value.id: 'f'+value.idfuncionario 
      },
      eleitorNome(strusuario, nomes) {
          var nome = '';
          nomes.forEach(el=>{
            if(strusuario == el.strusuario) {
                nome = el.strnome
            }
          })
          return nome;
      }
  }
};
</script>
<style  scoped>
.li-usuarios:hover {
  background-color: #f5f5f5;
}
.li-usuarios {
  cursor: pointer;
}
</style>