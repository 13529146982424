<template>
  <div>
    <div class="modal fade" id="AlterarSenha" tabindex="-1">
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title w-300 font-weight-bold">Alterar Senha</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <label for="strcgsenha">Senha Atual</label>
                <input
                  type="password"
                  class="form-control"
                  id="strcgsenha"
                  v-model="userInfo.strsenha"
                  maxlength="15"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="novasenha">Nova Senha</label>
                <input
                  type="password"
                  class="form-control"
                  id="novasenha"
                  v-model="userInfo.strnovasenha"
                  maxlength="15"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <label for="confirmarsenha">Confirmar Senha</label>
                <input
                  type="password"
                  class="form-control"
                  id="confirmarsenha"
                  v-model="userInfo.strcsenha"
                  maxlength="15"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
              <button type="button" class="btn btn-primary" @click="changePassword(userInfo)">
                <i class="fa fa-key"></i> Alterar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JWT from "jwt-decode";
export default {
  data() {
    return {
      userInfo: {}
    };
  },
  methods: {
    changePassword(userInfo) {
      let token = localStorage.getItem("token");
	  let user_logged = JSON.parse(localStorage.getItem("user"));
      
	  if (token) {
        JWT(token);
      }
	  
      let id = token ? JWT(token).id : null;

      console.log(id);
      if (userInfo.strsenha === undefined) {
        toastr.error("A senha é obrigatória");
        return false;
      }
      if (userInfo.strnovasenha === undefined) {
        toastr.error("Digite a nova senha");
        return false;
      }
      if (userInfo.strnovasenha != this.userInfo.strcsenha) {
        toastr.error("As senhas não coincidem");
        return false;
      }
      axios.put(`/usuarios/atualizarsenha/${user_logged.data.id}`, userInfo).then(
        res => {
          toastr.success(res.data.mensagem);
          swal.fire({
            title:"Senha Alterada!",
            text:"Faça login novamente para continuar",
            type:'success'
          }).then(res=>{
            localStorage.removeItem("token");
            localStorage.removeItem("first_name");
            localStorage.removeItem("user");
            location.href = process.env.VUE_APP_BASE_ROUTE;
          });
          return false;
        },
        err => {
          toastr.error(err.response.data.mensagem);
        }
      );
      return false;
    }
  }
};
</script>